import { graphql } from '~/generated/gql'

export const GetAllFolioImages = graphql(/* GraphQL */ `
  query GetAllFolioImages($first: Int, $after: String) {
    folioImages(first: $first, after: $after) {
      ... on QueryFolioImagesConnection {
        __typename
        edges {
          __typename
          node {
            id
            account {
              profile {
                name
              }
            }
            file {
              url
            }
            totalCountLikes
            hasLiked
            order
            caption
            createdAt
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
  }
`)
