'use client';

import 'swiper/css';
import { Badge, Drawer } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useContext } from 'react';
import { IdentityContext, SafeImage } from '~/app/_components';
import { AccountProfileCard } from '../AccountProfileCard';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { ProfessionsFilterContext } from '../ProfessionsFilterContext';
import { SearchFilterMobile } from './SearchFilterMobile';
import { closeButton, filterTitle, mobileDrawerBody,
// mobileProfileAdjustmentIcon,
mobileProfileAdjustmentIconBadgeLabel, mobileProfileCardWrapper } from './styles.css';
import { TrimmedAccountFragment } from '~/generated/graphql';
import { mobileProjectsAdjustmentIcon, mobileProjectsAdjustmentIconLifted } from '~/app/projects/styles.css';
export function SearchResultPageOuterMobile({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    freeTextSearch,
    locationSearch
  } = useContext(AccountsFilterContext);
  const {
    professions
  } = useContext(ProfessionsFilterContext);
  // const height = useWindowHeight()

  const {
    currentAccount
  } = useContext(IdentityContext);
  const hasFolioItems = currentAccount?.folioItems?.length && currentAccount?.folioItems?.length > 0;
  const pn = usePathname();
  const isOnMyContacts = pn.includes('/contacts');
  const [openedFilters, {
    open: openFilters,
    close: closeFilters
  }] = useDisclosure(false);
  function calcBadgeTruthy() {
    if (!professions.length && !freeTextSearch.length && !locationSearch.length) {
      return false;
    }
    return true;
  }
  function calcBadgeNumber() {
    let badgeNumber = professions.length + locationSearch.length;

    // Add 1 if freeTextSearch is not empty
    if (freeTextSearch) {
      badgeNumber += 1;
    }
    return badgeNumber;
  }
  return <>
      <div className={mobileProfileCardWrapper}>
        <div>
          {children}
          {/* <ScrollToTop /> */}
          {!pn.includes('/start') ?
        // <Badge
        //   data-testid="filter-action"
        //   className={mobileProfileAdjustmentIcon}
        //   radius="md"
        //   leftSection={<SafeImage alt="" width={15} height={15} src={'/icons/filterIcon.webp'} />}
        //   rightSection={
        //     calcBadgeTruthy() ? (
        //       <Badge
        //         size="md"
        //         circle
        //         style={{
        //           position: 'relative',
        //           left: '5px',
        //           color: 'black',
        //           zIndex: '100',
        //         }}
        //       >
        //         {calcBadgeNumber()}
        //       </Badge>
        //     ) : null
        //   }
        //   classNames={{ label: mobileProfileAdjustmentIconBadgeLabel }}
        //   onClick={openFilters}
        // >
        //   FILTER
        // </Badge>

        <Badge color="black" data-testid="filter-action" size="md" className={hasFolioItems ? mobileProjectsAdjustmentIcon : mobileProjectsAdjustmentIconLifted} radius="md" leftSection={<SafeImage alt="" width={15} height={15} src={'/icons/filterIcon.webp'} />} rightSection={calcBadgeTruthy() ? <Badge size="md" circle style={{
          position: 'relative',
          // bottom: '2px',
          left: '5px',
          color: 'black',
          zIndex: '100'
        }}>
                    {calcBadgeNumber()}
                  </Badge> : null} classNames={{
          label: mobileProfileAdjustmentIconBadgeLabel
        }} onClick={openFilters}>
              FILTER
            </Badge> : null}
        </div>

        {/* Filter */}
        <Drawer opened={openedFilters} onClose={closeFilters} closeButtonProps={{
        size: 'xl'
      }} padding="0px" title={isOnMyContacts ? 'FILTER CONTACTS' : 'FILTER COMMUNITY'} classNames={{
        body: mobileDrawerBody,
        close: closeButton,
        title: filterTitle
      }} data-sentry-element="Drawer" data-sentry-source-file="MobileResults.tsx">
          <SearchFilterMobile closeFilters={closeFilters} data-sentry-element="SearchFilterMobile" data-sentry-source-file="MobileResults.tsx" />
        </Drawer>
      </div>
    </>;
}
export function SearchResultPageInnerMobile({
  edges
}: {
  edges: {
    node: TrimmedAccountFragment;
    cursor: string;
  }[];
}) {
  return <>
      {edges.map(edge => <AccountProfileCard account={edge.node} key={edge.cursor} />)}
    </>;
}