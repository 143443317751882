import type { Route } from 'next'

export function accountRoute(id: string): Record<string, Route> {
  return {
    show: `/accounts/${id}` as Route,
    connect: `/accounts/${id}/connect` as Route,
  }
}

export function onboardingHelpRoute(): Record<string, Route> {
  return {
    connect: '/' as Route,
    iso: '/projects' as Route,
    feature: '/newFeature' as Route,
  }
}
