'use client';

import { Badge, Box, Card, Divider, Flex, Text, Title, Button, UnstyledButton } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { ReportUserMenu } from '../common';
import { ConnectButton } from '../common/ConnectButton';
import { desktopProfileCard, desktopProfileCardHeader, desktopProfileCardTitle, proBadgeLabel, profileCardCarouselWrapper } from './styles.css';
import { IdentityContext, ImagesCarousel, PeerAccountContext, SafeAvatarImage, StyledButton, useAppAuth } from '~/app/_components';
import { accountBrand, accountProfessions, accountRoute, accountType } from '~/utils';
import { AccountTier, AccountType } from '~/generated/graphql';
export function ProBadge() {
  return <Badge color="black" py="0px" px="3px" fz="8px" h="14px" classNames={{
    label: proBadgeLabel
  }} data-sentry-element="Badge" data-sentry-component="ProBadge" data-sentry-source-file="DesktopProfileCard.tsx">
      PRO
    </Badge>;
}
export function DesktopProfileCard() {
  const {
    account,
    navigateToProfile
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const {
    isSignedIn
  } = useAppAuth();
  const router = useRouter();
  const routeInfo = {
    show: accountRoute(account.id).show
  };
  return <>
      <Card className={desktopProfileCard} p="0" pt="sm" data-testid="profile-card-desktop" data-sentry-element="Card" data-sentry-source-file="DesktopProfileCard.tsx">
        <Box className={profileCardCarouselWrapper} onClick={navigateToProfile} my="sm" data-sentry-element="Box" data-sentry-source-file="DesktopProfileCard.tsx">
          <ImagesCarousel data-sentry-element="ImagesCarousel" data-sentry-source-file="DesktopProfileCard.tsx" />
        </Box>

        <Flex mx="xs" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
          {isSignedIn && !isCurrentUserProfile ? <ConnectButton variant="xSmall" disablePadding={true} noPadding={true} fz="13px" type="passive" /> : <Button style={{
          height: '34px',
          visibility: 'hidden'
        }} />}
        </Flex>

        <Card.Section mx="xs" data-testid="profile-card" w="100%" pb="xs" h="100px" data-sentry-element="unknown" data-sentry-source-file="DesktopProfileCard.tsx">
          <Flex align="flex-start" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
            <UnstyledButton onClick={navigateToProfile} className={desktopProfileCardHeader} data-testid="profile-card-header" w="100%" data-sentry-element="UnstyledButton" data-sentry-source-file="DesktopProfileCard.tsx">
              <Flex gap="sm" justify="flex-start" w="100%" mt="sm" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                <Flex direction="column" gap="4px" align="center" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                  <SafeAvatarImage radius="xs" account={account} data-sentry-element="SafeAvatarImage" data-sentry-source-file="DesktopProfileCard.tsx" />
                  {account.tier === AccountTier.Pro ? <ProBadge /> : null}
                </Flex>
                <div className={desktopProfileCardTitle}>
                  <Flex justify="space-between" align="center" w="100%" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                    <Text tt="uppercase" size="13px" fw={600} data-testid="go-to-profile-button" data-sentry-element="Text" data-sentry-source-file="DesktopProfileCard.tsx">
                      {account.profile?.name}
                    </Text>
                    {account.personalDetails?.openToTestShoots ? <Badge size="xs" color="black" mr="20px">
                        TEST SHOOTS
                      </Badge> : null}
                  </Flex>
                  <Flex direction="column" gap="0px" pt="4px" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                    {account.type !== AccountType.Personal ? <Text size="md" c={'black'} lineClamp={1} pr="md" fw={600} tt="uppercase">
                        {accountType(account)}
                      </Text> : null}

                    {account.type === AccountType.Personal && account.professions && account.professions.length > 0 ? <Text size="md" lineClamp={1} pr="md">
                        {accountProfessions(account)}
                      </Text> : null}
                    {account.profile?.location ? <Text size="md" lineClamp={2} pr="md" tt="capitalize">
                        {account.profile?.location}
                      </Text> : null}
                  </Flex>
                </div>
              </Flex>
            </UnstyledButton>
            {/* <Flex direction="column" mr="xs" pr="xs" gap="5px">
              {isSignedIn && !isCurrentUserProfile ? (
                <ConnectButton variant="xSmall" disablePadding={true} noPadding={true} fz="10px" />
              ) : null}
              <StyledButton
                text="VIEW FOLIO"
                width="82.2px"
                noPadding={true}
                type="black"
                fz="10px"
                disablePadding={true}
                onClick={() => router.push(routeInfo.show)}
                testId="view-folio-button"
                slimHeight={true}
              />
               <Flex justify="flex-end" mb="xs">
                <ReportUserMenu />
              </Flex>
             </Flex> */}
          </Flex>
        </Card.Section>
      </Card>
    </>;
}