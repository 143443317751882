'use client';

import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@urql/next';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, type UseFormReturn, useForm, useFormContext } from 'react-hook-form';
import type { ActorRefFrom } from 'xstate';
import * as z from 'zod';
import React from 'react';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { type OnboardingMachine, createCurrentUser } from '~/app/(me)/onboarding/_lib';
import { AuthActorContext, FormValidationProvider, IdentityContext } from '~/app/_components';
import { type AccountUpdateInput, type AccountUpdateableState, type AccountType, type ContactInfoCreateInput, type ContactInfoUpdateInput, type ProfileCreateInput, type ProfileUpdateInput, type SocialInfoCreateInput, type SocialInfoUpdateInput, type UserCreateInput,
// type PersonalDetails,
PersonalDetailsCreateInput, PersonalDetailsUpdateInput, DetailedCurrentAccountFragment, TrimmedAccountFragment } from '~/generated/graphql';
import { appTrace, findCountryByPhoneNumber, fullPhoneToISOCode, fullPhoneToShortPhone, isoCodeToPhoneCode } from '~/utils';
import { createValidationResolver, extractProfessions } from './CommonSchemaUtils';
import { usePathname } from 'next/navigation';
const websiteSchema = z.string().transform(val => {
  // Add protocol if missing and only allow www prefixed or domain names
  if (!/^https?:\/\//i.test(val)) {
    if (/^www\./i.test(val) || /^[a-z0-9.-]+\.[a-z]{2,}$/i.test(val)) {
      return `https://${val}`;
    }
  }
  return val;
}).refine(val => {
  try {
    const url = new URL(val);
    // Ensure it's a valid domain with an optional 'www'
    return /^(www\.)?[a-z0-9.-]+\.[a-z]{2,}$/.test(url.hostname);
  } catch (_error) {
    return false;
  }
}, {
  message: 'Invalid website address'
});
const contactInfoSchema = z.object({
  email: z.string().email().min(1),
  phoneISOCode: z.string().optional(),
  phoneShort: z.string().optional(),
  phone: z.string().optional()
}).superRefine((data, ctx) => {
  if (data.phoneShort && !data.phoneShort.startsWith('+') && !data.phoneISOCode) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'error',
      path: ['phoneShort']
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Country code is required when phone number is entered',
      path: ['phoneISOCode']
    });
  }
}).transform(({
  phoneISOCode,
  phoneShort,
  phone,
  ...data
}) => {
  // transformation is also needed in order to remove virtual phoneISOCode/phoneShort
  let isoCode = phoneISOCode;
  if (phoneShort?.startsWith('+')) {
    isoCode = findCountryByPhoneNumber(phoneShort)?.code;
  }
  const phoneCode = isoCode ? isoCodeToPhoneCode(isoCode) : '';
  const fullPhone = `${phoneCode}${phoneShort ?? ''}`;
  return {
    phone: phoneShort ? fullPhone : null,
    ...data
  };
});

// only registered fields are validated on each step
const accountSchema = z.object({
  profile: z.object({
    name: z.string().min(1),
    // about: z.string().min(10),
    location: z.string().min(1),
    gender: z.string().min(1)
    // brand: z.string().min(1)
  }),
  contactInfo: contactInfoSchema,
  personalDetails: z.object({
    openToTestShoots: z.union([z.string(), z.boolean()]).transform(val => val === 'true' || val === true),
    openToAgencies: z.union([z.string(), z.boolean()]).transform(val => val === 'true' || val === true),
    professionalLevel: z.string().optional(),
    height: z.coerce.number().optional()
  }),
  socialInfo: z.object({
    website: websiteSchema.or(z.literal('')).nullable().optional(),
    igHandle: z.string()
    //.regex(/^$|^[a-zA-Z0-9](?!.*[_.]{2})[a-zA-Z0-9._]{0,28}[a-zA-Z0-9]$/, {
    .regex(/^$|^[a-zA-Z0-9_](?!.*\.\.)[a-zA-Z0-9._]{0,28}[a-zA-Z0-9_]$/, {
      message: 'Invalid instagram handle.'
    }).nullable().optional()
  }),
  professions: z.array(z.string()).min(1, {
    message: 'Select at least one profession'
  }),
  type: z.string().min(1),
  currentAccount: z.object({
    avatarUrl: z.string().min(1)
  })
});
interface ContactInfoExtra {
  phoneISOCode: string;
  phoneShort: string;
}
type FullContactInfo = (ContactInfoCreateInput | ContactInfoUpdateInput) & ContactInfoExtra;
export interface ProfileFormValues {
  contactInfo: FullContactInfo;
  socialInfo: SocialInfoCreateInput | SocialInfoUpdateInput;
  profile: ProfileCreateInput | ProfileUpdateInput;
  personalDetails: PersonalDetailsCreateInput | PersonalDetailsUpdateInput;
  professions: string[];
  state: AccountUpdateableState;
  allowedPrevState?: AccountUpdateableState;
  type?: AccountType;
  currentAccount?: DetailedCurrentAccountFragment;
  // folders: Folder
}

// const isSSR = typeof window === 'undefined'

export function defaultPersonalDetails(account?: TrimmedAccountFragment) {
  return {
    height: account?.personalDetails?.height ?? undefined,
    dressSize: account?.personalDetails?.dressSize ?? undefined,
    pantSize: account?.personalDetails?.pantSize ?? undefined,
    // dressSize: account?.personalDetails?.height ?? undefined,
    // pantSize: account?.personalDetails?.height ?? undefined,
    // hips: account?.personalDetails?.hips ?? undefined,
    // waist: account?.personalDetails?.waist ?? undefined,
    // bust: account?.personalDetails?.bust ?? undefined,
    // eyes: account?.personalDetails?.eyes ?? undefined,
    // hair: account?.personalDetails?.hair ?? undefined,
    // shoe: account?.personalDetails?.shoe ?? undefined,
    openToTestShoots: account?.personalDetails?.openToTestShoots,
    openToAgencies: account?.personalDetails?.openToAgencies,
    agencyName: account?.personalDetails?.agencyName ?? '',
    professionalLevel: account?.personalDetails?.professionalLevel ?? undefined
  };
}
export function CurrentAccountFormProvider({
  children
}: React.PropsWithChildren) {
  const {
    user
  } = useAuth0();

  // TODO move fields that are not commonly used across the app to outside of trimmed account
  const {
    currentAccount: account
  } = useContext(IdentityContext);

  // const [{ data, fetching, error }] = useQuery({
  //   query: GetDetailedCurrentAccount,
  //   pause: isSSR,
  // })

  // const detailedCurrentAccount = data?.currentAccount

  // if (detailedCurrentAccount?.__typename !== 'Account') return null

  function bestPossibleDefaultName() {
    if (account?.profile?.name) return account.profile.name;
    if (user?.name) {
      appTrace('setting name based on the auth provider', user.name);
      if (user.name.includes('@')) {
        appTrace('not pre-filling email in the name field');
      } else {
        return user.name;
      }
    }
    return '';
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const form: UseFormReturn<ProfileFormValues, any, any> = useForm<ProfileFormValues>({
    resolver: createValidationResolver(accountSchema),
    mode: 'onChange',
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      contactInfo: {
        email: account?.contactInfo?.email ?? user?.email ?? '',
        phone: account?.contactInfo?.phone ?? '',
        phoneISOCode: fullPhoneToISOCode(account?.contactInfo?.phone),
        phoneShort: fullPhoneToShortPhone(account?.contactInfo?.phone)
      },
      socialInfo: {
        website: account?.socialInfo?.website ?? '',
        igHandle: account?.socialInfo?.igHandle ?? ''
      },
      profile: {
        ...account?.profile,
        name: bestPossibleDefaultName(),
        brand: account?.profile?.brand ?? '',
        gender: account?.profile?.gender,
        pronoun: account?.profile?.pronoun,
        location: account?.profile?.location ?? ''
      },
      personalDetails: defaultPersonalDetails(account),
      professions: account?.professions?.map(p => p.id) ?? [],
      //currentAccount: detailedCurrentAccount,
      currentAccount: account
    }
  });
  const {
    setValue
  } = form;
  useEffect(() => {
    if (user?.email && !account?.contactInfo?.email) {
      appTrace('setting email based on the auth provider', user.email);
      setValue('contactInfo.email', user.email);
    }
    if (!account?.profile?.name && user?.name) {
      setValue('profile.name', bestPossibleDefaultName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const pathname = usePathname();
  useEffect(() => {
    // WARNING:
    // This is needed to register the new fields
    // Don't know why it's necessary
    form.reset();
  }, [pathname]);
  return <FormValidationProvider schema={accountSchema} data-sentry-element="FormValidationProvider" data-sentry-component="CurrentAccountFormProvider" data-sentry-source-file="CurrentAccountFormProvider.tsx">
      <FormProvider {...form} data-sentry-element="FormProvider" data-sentry-source-file="CurrentAccountFormProvider.tsx">{children}</FormProvider>
    </FormValidationProvider>;
}
export function useProfileFormMutations() {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const form = useFormContext<ProfileFormValues>();
  const {
    handleSubmit,
    setError,
    clearErrors
  } = form;
  const [, executeOnboarding] = useMutation(createCurrentUser);
  const [, executeUpdate] = useMutation(updateCurrentAccount);
  const onCreate = useMemo(() => ({
    onboardingActor
  }: {
    onboardingActor: ActorRefFrom<OnboardingMachine>;
  }) => handleSubmit(async data => {
    appTrace('processing onCreate', JSON.stringify(data));
    const auth0token = authActor.getSnapshot().context.auth0token;
    if (!auth0token) {
      onboardingActor.send({
        type: 'creatingUser.error'
      });
      throw new Error('No auth0 token found');
    }
    const user: UserCreateInput = {
      settings: {
        create: {
          sendEmailNotification: true
        }
      },
      accounts: {
        create: [{
          contactInfo: {
            create: {
              ...data.contactInfo
            }
          },
          socialInfo: {
            create: {
              ...data.socialInfo
            }
          },
          profile: {
            create: {
              ...data.profile
            }
          },
          personalDetails: {
            create: {
              ...data.personalDetails
            }
          },
          professions: extractProfessions('connect', data.professions),
          type: data.type,
          state: data.state
        }]
      }
    };
    const res = await executeOnboarding({
      user,
      auth0token
    });
    const result = res.data?.createCurrentUser;
    if (result?.__typename === 'CurrentUserWithToken') {
      // WARNING we might need to set the token first
      // otherwise some queries might? be unpaused automatically
      // (based on context.account or context.user)
      // and sent without the bearer header
      authActor.send({
        type: 'API_TOKEN_SET',
        data: {
          token: result.token
        }
      });
      authActor.send({
        type: 'CURRENT_AUTH_CTX',
        data: {
          user: result.user,
          account: result.account
        }
      });
      onboardingActor.send({
        type: 'creatingUser.success'
      });
    } else {
      onboardingActor.send({
        type: 'creatingUser.error'
      });
      console.error(`Error creating user: ${result?.message}`);
    }
  }, validationErrors => {
    console.error(`Validation error: ${JSON.stringify(validationErrors)}`);
    onboardingActor.send({
      type: 'creatingUser.error'
    });
  }), []);

  // WARNING currently it sends every single field present in the account even if
  // there are no corresponding form inputs on the page
  // - not sure if we need to change it
  const onUpdate = useMemo(() => handleSubmit(async data => {
    console.log('DATA!!!!', data);
    appTrace('processing onUpdate', JSON.stringify(data));
    const account: AccountUpdateInput = {
      contactInfo: {
        update: {
          ...data.contactInfo
        }
      },
      socialInfo: {
        update: {
          ...data.socialInfo
        }
      },
      profile: {
        update: {
          ...data.profile
        }
      },
      personalDetails: {
        update: {
          ...data.personalDetails
        }
      },
      professions: extractProfessions('set', data.professions),
      state: data.state
      // folders: {create: {...data.}}
    };
    const res = await executeUpdate({
      account
    });
    const result = res.data?.updateCurrentAccount;
    if (result?.__typename === 'Account') {
      clearErrors();
      // TODO: this should not be necessary as the graphcache updates automatically
      // and identitycontext sends this update already
      authActor.send({
        type: 'CURRENT_ACCOUNT',
        data: {
          account: result
        }
      });
    } else {
      const message = result?.__typename === 'Error' ? result.message : 'update error';
      setError('root', {
        message
      });
      console.error(`Error updating user: ${message}`);
    }
  }, validationErrors => {
    console.error(`Validation error: ${JSON.stringify(validationErrors)}`);
  }), []);
  return {
    onCreate,
    onUpdate
  };
}