import { graphql } from '~/generated/gql'

// this query must not be located with components - breaks jest
// TODO needs to be deprecated
export const GetAccountWithContactInfo = graphql(/* GraphQL */ `
  query GetAuthorizedAccount($id: String!) {
    account(id: $id) {
      __typename
      ... on Account {
        ...TrimmedAccountWithContactInfo
      }
      ... on Error {
        message
      }
    }
  }
`)

export const GetDetailedAccountAsFriend = graphql(/* GraphQL */ `
  query GetDetailedAccountAsFriend($id: String!) {
    account(id: $id) {
      __typename
      ... on Account {
        ...DetailedAccountAsFriend
      }
      ... on Error {
        message
      }
    }
  }
`)
