import type { TextareaProps } from '@mantine/core';
import { usePathname } from 'next/navigation';
import { CommonTextareaField, labelDescription, textInputRoot } from '~/app/(me)/_components';
export function ProfileAboutField(props: TextareaProps) {
  const {
    label,
    name,
    ...restProps
  } = props;

  // const { currentAccount } = useContext(IdentityContext)
  // const isBusiness = currentAccount?.type !== AccountType.Personal
  const pn = usePathname();
  const isOnBusiness = pn.includes('/onboarding/business');
  // const isAgency = currentAccount?.type === AccountType.TalentAgency

  return <CommonTextareaField label={isOnBusiness ? 'ABOUT' : 'BIO'} name="profile.about" description={isOnBusiness ? "Describe your business and what you're known for" : null} placeholder="Write something..." minRows={6} size="md" data-testid="about-section" autosize={true}
  // pr="xs"
  classNames={{
    description: labelDescription,
    input: textInputRoot
  }} {...restProps} data-sentry-element="CommonTextareaField" data-sentry-component="ProfileAboutField" data-sentry-source-file="AboutField.tsx" />;
}