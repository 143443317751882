import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(accounts)%2F_components%2Fdesktop%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VVTWvcMBC951eoLIWk4MXe7ncvJS1pAwmUEuhx0VpjrxpZMpKcZFvy3zuS7dpeb5yEYHzwjGbmzbx58tjYvQCzYWBurcp%2FaJVwAV%2BoZptNvJzuFzQkf08IyehDcM%2BZ3a3JPAzzh0%2FOxmVtm6xmpW1L49tUq0KyIFZC6TUppAFL3vEsV9pSaf0ppRmgLxxjGDFKcEZGF5F7nNtqKg23XMl1LyHGTAwBaiDA8qrAfI8n4%2Be7WO%2FUHWjfSx%2FjKInc00E5lPU7UMRf5458VsZNLuh%2BTRIBfhRU8FQG3EJm1iQGaUE78%2B%2FCWJ7ssTRapG27UprjJxXxaThezDRk5AO5o%2Fo0CDKExCUEBr1wdjaM7oZbATW4yXFwFW9RGL53n84cMK4hLqeOcyky6Tw5ZYzLNNgqa1VWwYvGk5fCyxtc%2BKrCgPilaZ434%2FvoEdZ1sBXci3KZDmt786tSt1iPC20c17ni5bybRBnI4hJ5qsOmLwvD4mi5RCLrwFkZUi7V%2FQ65b5%2Fn8g4t14pR8RVMrHnuhl3HLn1sglsRGP4HkJslaqOrG%2B%2B9B57urNfhgbsqvBW44E%2FssgGq490NPNhLmRe2Lr7qcNDh%2BhWrWA7kvMBo%2BQ0Jya8g%2BV%2BCltrzyncsBwKdgaaMF6iPZXV9lO4SwNAJl0C7OQxnOHJkAO41Z6xRzrYNuE4RdqrokomoucSSJBks8dNF1BXi5yv0%2B4zmzzdanultqmP8im5B1ABYdbfrFO%2FSmvXqJm9tYjhe%2BR3oreAiDA8EeU5ZCp0a0NbEaLq8uDhfuaDPGTBOCeoAQBIqGTlt%2FVAWcyTrzIcekU9nd%2Bf%2BVFc60550CNlVoKNFeMRb%2F5IaLvsyIuTxCeDN3%2FGNwOdHoB2%2Fggc12el39qZ%2B%2FwGHbqzvIggAAA%3D%3D%22%7D"
export var desktopProfileCard = 'styles_desktopProfileCard__c84y7a0';
export var desktopProfileCardHeader = 'styles_desktopProfileCardHeader__c84y7a1';
export var desktopProfileCardTitle = 'styles_desktopProfileCardTitle__c84y7a2';
export var filterButtonGroupLeft = 'styles_filterButtonGroupLeft__c84y7aa';
export var filterButtonGroupMiddle = 'styles_filterButtonGroupMiddle__c84y7ab';
export var filterButtonGroupRight = 'styles_filterButtonGroupRight__c84y7ac';
export var filterIcon = 'styles_filterIcon__c84y7a5';
export var filterInputLabel = 'styles_filterInputLabel__c84y7ad';
export var inviteModalDescription = 'styles_inviteModalDescription__c84y7a8';
export var inviteModalInput = 'styles_inviteModalInput__c84y7a6';
export var inviteModalInputWrapper = 'styles_inviteModalInputWrapper__c84y7a7';
export var menuItem = 'styles_menuItem__c84y7a4';
export var proBadgeLabel = 'styles_proBadgeLabel__c84y7ae';
export var profileCardCarouselWrapper = 'styles_profileCardCarouselWrapper__c84y7a3';
export var searchTextInput = 'styles_searchTextInput__c84y7a9';