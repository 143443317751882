import { AccountType, TrimmedCurrentAccountFragment } from '~/generated/graphql'
import { onboardingPhrases } from './constants'

export function renderTitleText(path: string, account?: TrimmedCurrentAccountFragment) {
  return account?.type === AccountType.Personal
    ? titles[path] || path
    : account?.type === AccountType.Brand
      ? titlesBrand[path] || path
      : titlesAgency[path] || path
}
export function renderButtonText(path: string) {
  return buttons[path] || path
}

const titles: Record<string, string> = {
  '/onboarding/individual': onboardingPhrases.registrationTitle,
  '/onboarding/business': onboardingPhrases.registrationTitle,
  //'/signin': onboardingPhrases.signinTitle,
  '/onboarding/folio': onboardingPhrases.folioTitle,
  '/onboarding/profile': onboardingPhrases.registrationTitle2,
  '/onboarding/plan': onboardingPhrases.choosePlan,
}

const titlesBrand: Record<string, string> = {
  '/onboarding/individual': onboardingPhrases.registrationTitle,
  '/onboarding/business': onboardingPhrases.registrationTitle,
  //'/signin': onboardingPhrases.signinTitle,
  '/onboarding/folio': onboardingPhrases.folioTitleBrand,
  '/onboarding/profile': onboardingPhrases.registrationTitle2,
  '/onboarding/plan': onboardingPhrases.choosePlan,
}

const titlesAgency: Record<string, string> = {
  '/onboarding/individual': onboardingPhrases.registrationTitle,
  '/onboarding/business': onboardingPhrases.registrationTitle,
  //'/signin': onboardingPhrases.signinTitle,
  '/onboarding/folio': onboardingPhrases.folioTitleAgency,
  '/onboarding/profile': onboardingPhrases.registrationTitle2,
  '/onboarding/plan': onboardingPhrases.choosePlan,
}

const buttons: Record<string, string> = {
  '/onboarding/folio': onboardingPhrases.folioButton,
  '/onboarding/individual': onboardingPhrases.registrationButton1,
  // '/onboarding/business': onboardingPhrases.registrationButton2,
  // '/onboarding/profile': onboardingPhrases.registrationButton2,
  // '/onboarding/plan': onboardingPhrases.registrationButton3,
  '/onboarding/business': onboardingPhrases.registrationButton3,
  '/onboarding/profile': onboardingPhrases.registrationButton3,
}
