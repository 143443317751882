export const onboardingPhrases = {
  signinTitle: 'Welcome to Dome\nthe producer in your pocket',
  registrationTitle: "Let's get your new profile setup",
  registrationTitle2: 'Hi, tell us about yourself',
  registrationButton1: 'PROFESSIONAL DETAILS',
  registrationButton2: 'SETUP ACCOUNT',
  registrationButton3: 'ADD FOLIO',
  folioTitle: 'Add folio images to showcase\nyour best work',
  folioTitleBrand: 'Add folio images to showcase\nyour brand',
  folioTitleAgency: 'Add folio images to showcase\nyour agencies work',
  folioButton: 'EXPLORE DOME',
  choosePlan: 'Choose your subscription level',
}
