'use client';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge, Card, Flex, TextInput, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { StyledButton } from '~/app/_components';
// Breaks when trying to import it from it's index file
import { ProfessionsFilter } from '~/app/_components/search/desktop/ProfessionsFilter';
import { inputWrapperBorder } from '~/app/projects/styles.css';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { ProfessionsFilterContext } from '../ProfessionsFilterContext';
import { filterInputLabel } from './styles.css';
import { CommonAutocompleteInput } from '~/app/(me)/_components';
type SearchFilterProps = {
  toggleFilters: () => void;
};
export function SearchFilterDesktop({
  toggleFilters
}: SearchFilterProps) {
  const [keywordSearchInput, setKeywordSearchInput] = useState<string>('');
  const [locationSearchBadges, setLocationSearchBadges] = useState<string[]>([]);
  const {
    setProfessions
  } = useContext(ProfessionsFilterContext);
  const {
    setFreeTextSearch,
    freeTextSearch,
    locationSearch,
    setLocationSearch
  } = useContext(AccountsFilterContext);
  useEffect(() => {
    if (!locationSearch.length) return;
    setLocationSearchBadges(locationSearch);
  }, [locationSearch]);

  // keyword search
  useEffect(() => {
    if (freeTextSearch.length === 0) {
      setKeywordSearchInput('');
    }
  }, [freeTextSearch.length]);
  useEffect(() => {
    if (keywordSearchInput.length !== 0) {
      setFreeTextSearch(keywordSearchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleKeywordInputChange = (event: any) => {
    setKeywordSearchInput(event.target.value);
  };
  const handleKeywordSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setFreeTextSearch(keywordSearchInput);
    }
  };
  function handleResetOnclick() {
    setFreeTextSearch('');
    setLocationSearch([]);
    setProfessions([]);
    toggleFilters();
  }
  function handleFilterOnclick() {
    setFreeTextSearch(keywordSearchInput);
    // if (locationSearchInput.length > 0) {
    //   setLocationSearch((prevLocationSearch) => {
    //     // Merge the previous locationSearch with the new locationSearchInput
    //     const newLocationSearch = prevLocationSearch.concat(
    //       locationSearchInput.filter(
    //         (location) => !prevLocationSearch.includes(location),
    //       ),
    //     )
    //     return newLocationSearch
    //   })
    // }
    toggleFilters();
  }
  function handleLocationRemoval(location: string) {
    setLocationSearch(prevLocationSearch => {
      return prevLocationSearch.filter((loc: string) => loc !== location);
    });
    setLocationSearchBadges(prevLocationSearchBadges => {
      return prevLocationSearchBadges.filter((loc: string) => loc !== location);
    });
  }
  return <div data-testid="filter-modal" data-sentry-component="SearchFilterDesktop" data-sentry-source-file="SearchFilterDesktop.tsx">
      <Card px="100px" data-sentry-element="Card" data-sentry-source-file="SearchFilterDesktop.tsx">
        <Flex direction="column" gap="lg" data-sentry-element="Flex" data-sentry-source-file="SearchFilterDesktop.tsx">
          <Flex align="center" justify="center" direction="column" data-sentry-element="Flex" data-sentry-source-file="SearchFilterDesktop.tsx">
            <Text size={'13px'} fw={600} ta="left" w="100%" mb="6px" data-sentry-element="Text" data-sentry-source-file="SearchFilterDesktop.tsx">
              BY LOCATION
            </Text>
            <CommonAutocompleteInput autoclear={true} classNames={{
            input: inputWrapperBorder
          }} onPlaceSelected={place => {
            setLocationSearch(prevLocationSearch => {
              const newLocation = place?.formatted_address ?? '';
              return [...prevLocationSearch, newLocation];
            });
            setLocationSearchBadges(prevState => [...prevState, place?.formatted_address]);
          }} data-sentry-element="CommonAutocompleteInput" data-sentry-source-file="SearchFilterDesktop.tsx" />
            {locationSearchBadges.length ? locationSearchBadges.map((loc, i) => <Badge mt="sm" mr="sm" h={25} c="black" key={i} leftSection={<XMarkIcon height={15} onClick={_e => handleLocationRemoval(loc)} style={{
            cursor: 'pointer'
          }} />}>
                    {locationSearchBadges[i]}
                  </Badge>) : null}
          </Flex>

          <div>
            <Text size={'13px'} fw={600} ta="left" w="100%" mb="6px" data-sentry-element="Text" data-sentry-source-file="SearchFilterDesktop.tsx">
              BY PROFESSION
            </Text>
            <ProfessionsFilter data-sentry-element="ProfessionsFilter" data-sentry-source-file="SearchFilterDesktop.tsx" />
          </div>

          <Flex align="center" justify="center" direction="column" data-sentry-element="Flex" data-sentry-source-file="SearchFilterDesktop.tsx">
            <Text size={'13px'} fw={600} ta="left" w="100%" mb="6px" data-sentry-element="Text" data-sentry-source-file="SearchFilterDesktop.tsx">
              BY KEYWORD
            </Text>
            <TextInput
          // description="This will search for any matches within a users profile"
          w="100%" defaultValue={keywordSearchInput.length ? keywordSearchInput : freeTextSearch} onChange={e => handleKeywordInputChange(e)} onKeyDown={e => handleKeywordSearch(e)} data-testid="search-input" classNames={{
            label: filterInputLabel,
            input: inputWrapperBorder
          }} data-sentry-element="TextInput" data-sentry-source-file="SearchFilterDesktop.tsx" />
          </Flex>
        </Flex>
      </Card>

      <Flex justify="space-between" align="center" mt="md" pt="sm" style={{
      borderTop: '1px solid #efefef'
    }} data-sentry-element="Flex" data-sentry-source-file="SearchFilterDesktop.tsx">
        <StyledButton fz="15px" type="passive" text="RESET" onClick={handleResetOnclick} data-sentry-element="StyledButton" data-sentry-source-file="SearchFilterDesktop.tsx" />
        <Flex gap="sm" data-sentry-element="Flex" data-sentry-source-file="SearchFilterDesktop.tsx">
          <StyledButton fz="15px" type="passive" text="CANCEL" data-testid="filter-cancel" onClick={() => {
          toggleFilters();
        }} data-sentry-element="StyledButton" data-sentry-source-file="SearchFilterDesktop.tsx" />
          <StyledButton fz="15px" type="primary" text="FILTER" onClick={handleFilterOnclick} testId="filter-button" data-sentry-element="StyledButton" data-sentry-source-file="SearchFilterDesktop.tsx" />
        </Flex>
      </Flex>
    </div>;
}