import type { Route } from 'next'
import { EditProfileTab } from '~/app/(me)/(authorized)/profile/[[...tabParams]]/common'

export function meRoute(): Record<string, Route> {
  return {
    editProfile: '/profile' as Route,
  }
}

export function editProfileTabRoute(tab: EditProfileTab) {
  if (!tab) return meRoute().editProfile
  return `/profile/${tab}` as Route
}
