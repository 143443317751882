'use client';

import { Flex, Modal, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@urql/next';
import { useContext } from 'react';
import { phoneModal, phoneModalMobileBody, phoneModalMobileContent } from '~/app/(accounts)/accounts/styles.css';
import { AppLayoutContext, PeerAccountContext, StyledButton } from '~/app/_components';
import { GetAccountWithContactInfo } from '~/app/_graphql';
function copyToClipboard(number: string) {
  if (!number) return;
  navigator.clipboard.writeText(number);
  notifications.show({
    title: 'Copied',
    message: 'Phone number copied to clipboard',
    color: 'green'
  });
}
const isSSR = typeof window === 'undefined';
export function ProfileContactInfo() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  const [{
    data
  }] = useQuery({
    query: GetAccountWithContactInfo,
    variables: {
      id: account.id
    },
    pause: isSSR || !account.id
  });
  if (data?.account?.__typename !== 'Account' || !data.account.contactInfo) return null;
  const accountWithInfo = data?.account;
  const handleEmailClick = () => {
    const sendToName = accountWithInfo?.profile?.name;
    const sendToEmail = accountWithInfo?.contactInfo?.email;
    const subject = `Dome Message: Hi ${sendToName}`;

    // Construct the mailto link
    const mailtoLink = `mailto:${sendToEmail}?subject=${encodeURIComponent(subject)}`;

    // Open the email client
    window.location.href = mailtoLink;
  };
  return <Flex data-testid="contact-info-div" direction={mobile ? 'row' : 'column'} gap="xs" justify={mobile ? 'space-between' : 'center'} w={mobile ? '100%' : '100%'} data-sentry-element="Flex" data-sentry-component="ProfileContactInfo" data-sentry-source-file="ProfileContactInfo.tsx">
      {accountWithInfo.contactInfo?.email ? <StyledButton type={mobile ? 'passive' : 'black'} text="EMAIL" width={mobile ? '50%' : 'undefined'} shape="square"
    // disablePadding
    // textColor={theme.colors.brand[4]}
    onClick={handleEmailClick} testId="email-button" /> : null}
      {accountWithInfo.contactInfo?.phone ? <StyledButton type={mobile ? 'passive' : 'black'} text="PHONE" width={mobile ? '50%' : 'undefined'}
    // disablePadding
    shape="square"
    // textColor={theme.colors.brand[4]}
    onClick={open} testId="phone-button" /> : null}

      <Modal opened={opened} onClose={close} withCloseButton={false} title={`${account.profile?.name?.toUpperCase()}'S NUMBER`} radius="25px" centered classNames={{
      title: phoneModal,
      content: mobile ? phoneModalMobileContent : undefined,
      body: mobile ? phoneModalMobileBody : undefined
    }} style={{
      borderRadius: '25px'
    }} data-sentry-element="Modal" data-sentry-source-file="ProfileContactInfo.tsx">
        <Flex direction="column" h="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="ProfileContactInfo.tsx">
          <Flex justify="center" align="center" data-sentry-element="Flex" data-sentry-source-file="ProfileContactInfo.tsx">
            <Title order={1} ta="center" p="50px" data-sentry-element="Title" data-sentry-source-file="ProfileContactInfo.tsx">
              {accountWithInfo.contactInfo?.phone}
            </Title>
          </Flex>

          <Flex style={{
          borderTop: '1px solid #f1f1f1'
        }} data-sentry-element="Flex" data-sentry-source-file="ProfileContactInfo.tsx">
            <Flex mt="md" w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="ProfileContactInfo.tsx">
              <StyledButton text="CANCEL" type="passive" disablePadding onClick={close} data-sentry-element="StyledButton" data-sentry-source-file="ProfileContactInfo.tsx" />
              <StyledButton text="COPY NUMBER TO CLIPBOARD" type="primary" disablePadding onClick={() => copyToClipboard(accountWithInfo.contactInfo?.phone ?? '')} data-sentry-element="StyledButton" data-sentry-source-file="ProfileContactInfo.tsx" />
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </Flex>;
}