import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2Fprojects%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1X227jNhB9z1ewKBZIFpWhiyUrzkubLhYNsAsU6wX6GFAiZTORSIGkb7vwv5eiJIuW6Eudbp%2BKxEgy0pw5c2Y4w4yE3OZYPEOEHpdSMvr8HN6HRVi44PsNAOmSC8anoGSESswfbnY3o8aj5OwFp3ImocSPEM1x6%2BnVniyvHJMcpq%2BmG8LiVbJyhnPljNGfNcrvkKPW39f%2BBaHOApP5Qk6BF7rl5kEZEwU252xJkdPgE7rAnEjwEylKxiWk0gxWsITk2Igxq%2B1NpEBHugxUMYIbZ02QXEzBffiutvA5oVPgAriUzAycMiUXlTMVljD6WdNoo47r%2FLSvkzAlejEFKczTW2%2FkhxwX4D1YQX7rOIWKTCh2hHqK7%2B6smWXqx18cliXmbYBQB2ioeq77znTMWQorTk%2B0XMqeY2Qyy3GmpPe18kZiiglFmP%2BBIeocJ2bEcVgXq61e%2FfdZiGkOhXRY5shtiYdEeiBN9%2BksPiqxW5hYe2bK4gjyDavajMJIq2p6zzlBT5R20e%2BbgPsK92UrGIL5VyK7MsJ%2BJN8vN72W0U%2FXjQ4T1z3Spgi%2FCnUmPg9iJF2MYyjnKJTqZBM6b1T0wqMvKAKW5z0FPhKcowOKaV8GL7oUwmsx0FkpOwxi9O0j40YH1U2TaJuioRAEywkCP7sDyeqXHA4RWYqqR5oOuTQRoafXB87KD2y9H5nZP5CC4%2BypgHMsdNFbhLlG2I891YNH3KGeK19hIj7BBO%2F9FwMGcX0WD1oocl07VvVpociR2Sg5pKKEXE23k53mnkhdlIwKssJfGhHamC86Jkuqk%2B1kRKGkbHW4dYbOj2Te%2Br9e6X84n%2FNrUGYFzPNDnOI8zsF%2BEr%2Bhl6WQhVL2Ke0WMdUwiIgyh9spyHLcP8GVF8m2TrN0pkDVJ8UOXmGab%2FtFYoJU5VZAZINRfRjqFRQ0S7auXxCu1g%2FGefK785R51VcPeD%2Ft%2B4XvNpE%2FHD7D%2FmouDPt2alajO5qcXo0AfHOIWisbfW5q5htHLCBi67obx82n%2Bp3PE3jr%2FgKab3X%2B7dvVUpdPJFP3lrY67L%2BqzuT%2F6vR2v3lnLHUZ9GxqBOxzr2AEwFBgRcRhS3kOcbqoTuuRKWhTeXfza4ERgUCkHGMKIEXgtrrGtgpHnkruTiNeOgv35fHdpv71zdhiVXeXtsq9lwfm3UkGg4Ha3ey80ELCtBokei8PzLsjghl3sMi9v1owb2ITzLQaXHsvD8zXChaENsFMq0Gi9%2FLAfIFg4%2BANgkVWwSK7YJFdsOitggVWwQK7YIFdsOBywYLJGwSzcvXsXD07V6%2Fjep1gfmwjYVrNuRBbSezNFwjmT66fYVaqdqZ2ovEbxfJia4vH9haP7S0eu6fFMgbjJDiv1ZHrZ%2B%2F%2F98qwL5d%2F0SE7eiM9Ae25Z5LrOmE88X9Ecoa8%2F3ZysX9xbv4FTX5FbuEPy61bVLu%2FAQJ%2FOnbSEwAA%22%7D"
export var actionTabsLabel = 'styles_actionTabsLabel__595m5mh';
export var actionTabsTabs = 'styles_actionTabsTabs__595m5mi';
export var addButton = 'styles_addButton__595m5m0';
export var calenderHeader = 'styles_calenderHeader__595m5m7';
export var contentSectionMobile = 'styles_contentSectionMobile__595m5m4';
export var dekstopModalTitle = 'styles_dekstopModalTitle__595m5mb';
export var desktopSelectedProjectCard = 'styles_desktopSelectedProjectCard__595m5m2';
export var gridInner = 'styles_gridInner__595m5m9';
export var inputWrapperBorder = 'styles_inputWrapperBorder__595m5me';
export var locationInputWrapper = 'styles_locationInputWrapper__595m5m6';
export var mobileProfileWrapper = 'styles_mobileProfileWrapper__595m5m5';
export var mobileProjectCardStyles = 'styles_mobileProjectCardStyles__595m5m3';
export var mobileProjectsAdjustmentIcon = 'styles_mobileProjectsAdjustmentIcon__595m5mn';
export var mobileProjectsAdjustmentIconLifted = 'styles_mobileProjectsAdjustmentIconLifted__595m5mo';
export var modalFieldTitle = 'styles_modalFieldTitle__595m5mc';
export var modalFieldTitle1 = 'styles_modalFieldTitle1__595m5md';
export var modalTitle = 'styles_modalTitle__595m5ma';
export var projectCard = 'styles_projectCard__595m5mp';
export var projectInputFont = 'styles_projectInputFont__595m5m8';
export var projectStateBadge = 'styles_projectStateBadge__595m5m1';
export var refImagesModal = 'styles_refImagesModal__595m5mg';
export var responsiveRefImage = 'styles_responsiveRefImage__595m5mj';
export var responsiveRefImageBig = 'styles_responsiveRefImageBig__595m5mk';
export var responsiveRefImageBigMobile = 'styles_responsiveRefImageBigMobile__595m5ml';
export var responsiveRefImageSmallMobile = 'styles_responsiveRefImageSmallMobile__595m5mm';
export var selectDropDown = 'styles_selectDropDown__595m5mf';