import { Flex, Textarea } from '@mantine/core';
import { useMutation } from '@urql/next';
import React from 'react';
import { editCaptionModalBody } from '~/app/(accounts)/accounts/styles.css';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { StyledButton } from '~/app/_components';
import type { AccountUpdateFolioItemsRelationInputUpdate, AccountUpdateInput, AccountUpdateVideoItemsRelationInputUpdate, FolioItem, VideoItem } from '~/generated/graphql';
export function CaptionModalInner({
  selectedImage,
  selectedVideo,
  setSelected,
  closeCaption
}: {
  selectedImage?: FolioItem;
  selectedVideo?: VideoItem;
  setSelected: any;
  closeCaption: () => void;
}) {
  const [caption, setCaption] = React.useState<string | undefined>(undefined);
  const [, update] = useMutation(updateCurrentAccount);
  function saveCaption(itemId: string) {
    let accountUpdateInput: AccountUpdateInput = {};
    if (selectedImage) {
      const folioItem: AccountUpdateFolioItemsRelationInputUpdate = {
        where: {
          id: itemId
        },
        data: {
          caption
        }
      };
      accountUpdateInput = {
        folioItems: {
          update: [folioItem]
        }
      };
    } else {
      const videoItem: AccountUpdateVideoItemsRelationInputUpdate = {
        where: {
          id: itemId
        },
        data: {
          caption
        }
      };
      accountUpdateInput = {
        videoItems: {
          update: [videoItem]
        }
      };
    }
    update({
      account: accountUpdateInput
    });
    setSelected(undefined);
  }
  return <>
      <Textarea defaultValue={selectedImage?.caption ?? selectedVideo?.caption ?? ''} minRows={3} name="captionInput" onChange={e => setCaption(e.target.value)} classNames={{
      input: editCaptionModalBody
    }} data-sentry-element="Textarea" data-sentry-source-file="CaptionModalInner.tsx" />
      <Flex justify="space-between" gap="sm" pt="sm" data-sentry-element="Flex" data-sentry-source-file="CaptionModalInner.tsx">
        <StyledButton type="passive" text="Cancel" onClick={() => {
        closeCaption();
        setSelected(undefined);
      }} data-sentry-element="StyledButton" data-sentry-source-file="CaptionModalInner.tsx" />
        <StyledButton type="primary" text="Save" onClick={() => {
        closeCaption();
        saveCaption(selectedImage ? selectedImage.id : selectedVideo?.id ?? '');
        setSelected(undefined);
      }} data-sentry-element="StyledButton" data-sentry-source-file="CaptionModalInner.tsx" />
      </Flex>
    </>;
}