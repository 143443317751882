import { Badge, Card, CardSection, Flex, Modal, Tabs, Text } from '@mantine/core';
import { AccountAvatarBlock, ConnectButton } from '~/app/(accounts)/_components';
import { IdentityContext, PeerAccountContext, SafeImage, StyledButton } from '~/app/_components';
import { meRoute } from '~/utils';
import { useContext, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { businessAccountTabLabel, businessAccountTabLabelActive, editCaptionModalTitle, responsiveImageMobile, responsiveImageMobileInner, responsiveImageMobileOuter } from '~/app/(accounts)/accounts/styles.css';
import { HeartIcon } from '@heroicons/react/24/outline';
import { CaptionModalInner, ProfileContactInfo } from '../desktop';
import { useDisclosure } from '@mantine/hooks';
import { ConnectionState, FolioItem } from '~/generated/graphql';
import { MobileLikeImage } from './MobileLikeImage';
export function ProfileMobile() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const [cutText, setCutText] = useState(true);
  function renderAboutTextIcon() {
    if (cutText) {
      return <ChevronDownIcon height={25} onClick={() => setCutText(false)} />;
    }
    return <ChevronUpIcon height={25} onClick={() => setCutText(true)} data-sentry-element="ChevronUpIcon" data-sentry-component="renderAboutTextIcon" data-sentry-source-file="ProfileMobile.tsx" />;
  }
  return <Flex justify="center" mb="xl" data-sentry-element="Flex" data-sentry-component="ProfileMobile" data-sentry-source-file="ProfileMobile.tsx">
      <Card p={0} mt="65px" w="100%" style={{
      border: 'none',
      backgroundColor: 'transparent'
    }} data-sentry-element="Card" data-sentry-source-file="ProfileMobile.tsx">
        <CardSection h="100%" data-testid="profile-card" m="15px" data-sentry-element="CardSection" data-sentry-source-file="ProfileMobile.tsx">
          <Flex direction="column" h="100%" justify="space-between" gap="15px" data-sentry-element="Flex" data-sentry-source-file="ProfileMobile.tsx">
            <AccountAvatarBlock account={account} data-sentry-element="AccountAvatarBlock" data-sentry-source-file="ProfileMobile.tsx" />

            <Flex justify="space-between" data-sentry-element="Flex" data-sentry-source-file="ProfileMobile.tsx">
              <Text data-testid="about-mobile-section" size="md" lineClamp={cutText ? 2 : undefined} w={'95%'} data-sentry-element="Text" data-sentry-source-file="ProfileMobile.tsx">
                {account.profile?.about}
              </Text>
              {account.profile?.about ? renderAboutTextIcon() : null}
            </Flex>

            {isCurrentUserProfile ? <Flex>
                <StyledButton type="passive" text="UPDATE PROFILE" fz="11px" fw={700} href={meRoute().editProfile} width="100%" shape="square" />
              </Flex> : account.connectionStatus === ConnectionState.Connected ? <ProfileContactInfo /> : <ConnectButton business />}
            <MobileFolioTab data-sentry-element="MobileFolioTab" data-sentry-source-file="ProfileMobile.tsx" />
          </Flex>
        </CardSection>
      </Card>
    </Flex>;
}
function EmptyProfileForOwner() {
  // const { account } = useContext(PeerAccountContext)
  // const router = useRouter()

  // this can theoretically happen when clicking a link
  // to a no folio profile
  // if (!account.folioItems?.length) router.push('/')

  return <Flex direction="column" gap="30px" h="500px" w="100%" style={{
    border: '1px dotted black'
  }} justify="center" align="center" px="40px" data-sentry-element="Flex" data-sentry-component="EmptyProfileForOwner" data-sentry-source-file="ProfileMobile.tsx">
      <Text size="md" ta="center" data-sentry-element="Text" data-sentry-source-file="ProfileMobile.tsx">
        Your profile is currently not visible to other users because it’s missing folio images.
      </Text>
      <StyledButton text="ADD FOLIO IMAGES" type="passive" disablePadding href={meRoute().editProfile} data-sentry-element="StyledButton" data-sentry-source-file="ProfileMobile.tsx" />
    </Flex>;
}
export function NonEmptyFolioProfileMobile() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const [selectedImage, setSelectedImage] = useState<FolioItem | undefined>(undefined);
  const [opened, {
    open: captionOpen,
    close: captionClose
  }] = useDisclosure(false);
  function handleBadgeOnClick(item: FolioItem) {
    setSelectedImage(item);
    captionOpen();
  }
  return <>
      <div className={responsiveImageMobileOuter}>
        {account.folioItems?.map((image, index) => <div key={index} className={responsiveImageMobileInner}>
            <Flex direction="column" mb="5px">
              <SafeImage src={image.file.url} alt={`Image ${index + 1}`} priority={image.order === 0 || image.order === 1} width={500} // Set a default width
          height={750} // Set a default height, aspect ratio will be maintained by CSS
          className={responsiveImageMobile} style={{
            objectFit: 'cover',
            display: 'block',
            borderRadius: '10px'
          }} breakpoints={{
            xs: 100,
            sm: 70,
            md: 50,
            lg: 30,
            xl: 20
          }} />
              {isCurrentUserProfile ? <>
                  <Flex gap="5px" align="center">
                    <HeartIcon height={16} />
                    <Text size="sm">{image.caption?.length ? image.caption : 'Caption'}</Text>
                  </Flex>
                  <Badge color="black" size="lg" radius="md" mt="2px" style={{
              cursor: 'pointer'
            }} onClick={() => handleBadgeOnClick(image)}
            // classNames={{
            //   root: captionBadge,
            // }}
            >
                    {image.caption?.length ? 'EDIT CAPTION' : 'ADD CAPTION'}
                  </Badge>
                </> : currentAccount ? <MobileLikeImage image={image} id={image.id} /> : null}
            </Flex>
          </div>)}
      </div>
      <Modal opened={opened} onClose={() => {
      setSelectedImage(undefined);
      captionClose();
    }} withCloseButton={false} centered title="GIVE YOUR IMAGE A CAPTION" classNames={{
      title: editCaptionModalTitle
    }} data-sentry-element="Modal" data-sentry-source-file="ProfileMobile.tsx">
        <CaptionModalInner selectedImage={selectedImage} setSelected={setSelectedImage} closeCaption={captionClose} data-sentry-element="CaptionModalInner" data-sentry-source-file="ProfileMobile.tsx" />
      </Modal>
    </>;
}
function MobileFolioTab() {
  const {
    account
  } = useContext(PeerAccountContext);
  const [activeTab, setActiveTab] = useState<string | null>('folio');
  return <Flex direction="row" w="100%" data-sentry-element="Flex" data-sentry-component="MobileFolioTab" data-sentry-source-file="ProfileMobile.tsx">
      <Tabs defaultValue="folio" orientation="horizontal" variant="pills" onChange={setActiveTab} placement="left" w="100%" radius="8px" data-sentry-element="Tabs" data-sentry-source-file="ProfileMobile.tsx">
        <Tabs.List mb="18px" grow data-sentry-element="unknown" data-sentry-source-file="ProfileMobile.tsx">
          <Tabs.Tab value="folio" color={activeTab === 'folio' ? '#000' : 'transparent'} fw={600} fz="h3"
        // style={{ paddingRight: isAgency ? '10px' : '50px', paddingLeft: isAgency ? '10px' : '50px' }}
        classNames={{
          tabLabel: activeTab === 'folio' ? businessAccountTabLabelActive : businessAccountTabLabel
        }} style={{
          height: '34px'
        }} data-sentry-element="unknown" data-sentry-source-file="ProfileMobile.tsx">
            FOLIO
          </Tabs.Tab>
        </Tabs.List>
        <Flex justify="flex-start" data-sentry-element="Flex" data-sentry-source-file="ProfileMobile.tsx">
          <Tabs.Panel value="folio" data-sentry-element="unknown" data-sentry-source-file="ProfileMobile.tsx">
            {account.folioItems && account.folioItems?.length >= 1 ? <NonEmptyFolioProfileMobile /> : <EmptyProfileForOwner />}
          </Tabs.Panel>
        </Flex>
      </Tabs>
    </Flex>;
}