import { AtSymbolIcon, EnvelopeIcon, FlagIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Flex, Input, Text } from '@mantine/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CommonSelectField, CommonTextField, ProfileFormValues } from '~/app/(me)/_components/common/form';
import { AppLayoutContext, SafeImage } from '~/app/_components';
import { formLabel, labelDescription, textInputRoot } from './styles.css';
import { countryCodes } from '~/utils/countries';
import { useFormContext, useWatch } from 'react-hook-form';
export function ContactInfoFields({
  isOnboarding
}: {
  isOnboarding?: boolean;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const form = useFormContext<ProfileFormValues>();
  const countryCodeData = useMemo(() => countryCodes.map(({
    phoneCode,
    flagEmoji,
    name,
    code
  }) => ({
    value: code,
    label: `${flagEmoji} ${name} (${phoneCode})`
  })), []);
  const phoneShort = useWatch({
    control: form.control,
    name: 'contactInfo.phoneShort'
  });
  const phoneISOCode = useWatch({
    control: form.control,
    name: 'contactInfo.phoneISOCode'
  });
  useEffect(() => {
    if (!phoneShort) return;
    form.trigger('contactInfo.phoneISOCode');
  }, [phoneShort]);
  useEffect(() => {
    form.trigger('contactInfo.phoneShort');
  }, [phoneISOCode]);
  return <>
      <Flex direction="column" mt="md" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
        <Input.Label fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" mb={mobile ? '0px' : '3px'} data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
          CONTACT INFO
        </Input.Label>
        <Text c="dimmed" mb="md" data-sentry-element="Text" data-sentry-source-file="ContactInfoFields.tsx">
          Your email and phone number will be shared with users you connect with.
        </Text>

        <Flex gap="sm" align="flex-end" justify="space-between" mb="sm" direction="column" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
          <Input.Wrapper label="EMAIL"
        // description={props.description}
        // fz={mobile ? '13px' : 'h4'}
        // fw={mobile ? 600 : 600}
        w="100%" classNames={{
          label: formLabel
        }} data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
            <CommonTextField required size={isOnboarding ? mobile ? 'md' : 'md' : 'md'}
          // description="Your email and phone number will be shared with users you connect with."
          leftSection={<EnvelopeIcon height="20px" width="20px" color="black" />} name="contactInfo.email" placeholder="Email" type="email" classNames={{
            description: labelDescription,
            input: textInputRoot
          }} data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
          </Input.Wrapper>

          <Flex w="100%" gap="sm" direction={mobile ? 'column' : 'row'} align="flex-end" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
            <Input.Wrapper label="PHONE"
          // description={props.description}
          // fz={mobile ? '13px' : 'h4'}
          // fw={mobile ? 600 : 600}
          w="100%" classNames={{
            label: formLabel
          }} data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
              <CommonSelectField
            // label="Country"
            placeholder="Phone Country Code" size="md" clearable searchable width={mobile ? '100%' : '50%'} name="contactInfo.phoneISOCode" leftSection={<FlagIcon height="20px" width="20px" color="black" />} data={countryCodeData}
            // {...restProps}
            data-sentry-element="CommonSelectField" data-sentry-source-file="ContactInfoFields.tsx" />
            </Input.Wrapper>

            <CommonTextField size={isOnboarding ? mobile ? 'md' : 'md' : 'md'} leftSection={<PhoneIcon height="20px" width="20px" color="black" />} name="contactInfo.phoneShort" placeholder="Phone number (optional)" type="tel" mb="3px" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
          </Flex>
        </Flex>

        <Input.Label fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" mb={mobile ? '0px' : '3px'} mt="xl" data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
          FOLIO
        </Input.Label>
        <Text c="dimmed" mb="md" data-sentry-element="Text" data-sentry-source-file="ContactInfoFields.tsx">
          The following information will be shared with all users.
        </Text>

        <Flex direction="column" gap="sm" data-sentry-element="Flex" data-sentry-source-file="ContactInfoFields.tsx">
          <Input.Wrapper label="WEBSITE"
        // description={props.description}
        // fz={mobile ? '13px' : 'h4'}
        // fw={mobile ? 600 : 600}
        w="100%" classNames={{
          label: formLabel
        }} data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
            <CommonTextField name="socialInfo.website"
          // description="The following information will be shared with all users."
          size={isOnboarding ? mobile ? 'md' : 'md' : 'md'}
          // leftSection={<GlobeAltIcon height="20px" width="20px" color='black'/>}
          leftSection={<SafeImage alt="avatar" height={20} width={20}
          // sizes={`${size}px`}
          style={{
            objectFit: 'cover'
          }} src={'/icons/WebIcon.webp'}
          // sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, (min-width: 1407px) 33vw"
          />} placeholder="Website (optional)" classNames={{
            description: labelDescription,
            input: textInputRoot
          }} data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
          </Input.Wrapper>

          <Input.Wrapper label="INSTAGRAM"
        // description={props.description}
        // fz={mobile ? '13px' : 'h4'}
        // fw={mobile ? 600 : 600}
        w="100%" classNames={{
          label: formLabel
        }} data-sentry-element="unknown" data-sentry-source-file="ContactInfoFields.tsx">
            <CommonTextField name="socialInfo.igHandle" size={isOnboarding ? mobile ? 'md' : 'md' : 'md'} leftSection={<AtSymbolIcon height="20px" width="20px" color="black" />} placeholder="Instagram handle (optional)" data-sentry-element="CommonTextField" data-sentry-source-file="ContactInfoFields.tsx" />
          </Input.Wrapper>
        </Flex>
      </Flex>
    </>;
}