import { graphql } from '~/generated/gql'

// TODO needs to be deprecated / rewritten to fragments
export const GetAccount = graphql(/* GraphQL */ `
  query GetAccount($id: String!) {
    account(id: $id) {
      __typename
      ... on Account {
        ...FullAccountAsViewer
        authoredMessages {
          __typename
          edges {
            cursor
            node {
              authorId
              content
              createdAt
              id
              recipientId
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        receivedMessages {
          __typename
          edges {
            cursor
            node {
              authorId
              content
              createdAt
              id
              recipientId
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        receivedFriendships {
          edges {
            cursor
            node {
              id
              addressee {
                id
              }
              requester {
                id
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        requestedFriendships {
          edges {
            cursor
            node {
              addressee {
                id
              }
              requester {
                id
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        friends {
          edges {
            cursor
            node {
              id
              profile {
                name
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
      ... on Error {
        message
      }
    }
  }
`)

export const GetTrimmedAccount = graphql(/* GraphQL */ `
  query GetTrimmedAccount($id: String!) {
    account(id: $id) {
      __typename
      ... on Account {
        ...TrimmedAccount
      }
      ... on Error {
        message
      }
    }
  }
`)
