import { Stack } from '@mantine/core';
import { useContext } from 'react';
import { CommonLocationField, CommonTextField, ProfileAboutField, ProfileGenderField, ProfilePronounsField } from '~/app/(me)/_components/common/form';
import { IdentityContext } from '~/app/_components';
import { AccountType } from '~/generated/graphql';
export function ProfileFields() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isBusiness = currentAccount?.type !== AccountType.Personal;
  const isAgency = currentAccount?.type === AccountType.TalentAgency;
  return <>
      <Stack gap="xl" data-sentry-element="Stack" data-sentry-source-file="ProfileFields.tsx">
        <CommonTextField label={!isBusiness ? 'NAME' : isAgency ? 'AGENCY NAME' : 'BRAND NAME'} name="profile.name" data-sentry-element="CommonTextField" data-sentry-source-file="ProfileFields.tsx" />

        <CommonLocationField name="profile.location" data-sentry-element="CommonLocationField" data-sentry-source-file="ProfileFields.tsx" />

        {/* {isBusiness ? null : <ProfileTextField label="Brand" name="profile.brand" />} */}
        {isBusiness ? null : <ProfileGenderField />}
        {isBusiness ? null : <ProfilePronounsField />}

        <ProfileAboutField data-sentry-element="ProfileAboutField" data-sentry-source-file="ProfileFields.tsx" />
      </Stack>
    </>;
}