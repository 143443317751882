'use client';

import { useContext, useState } from 'react';
import { IdentityContext, PeerAccountContext, SafeImage, useAppAuth } from '~/app/_components';
import { FolioItem } from '~/generated/graphql';
import { CaptionModalInner, useLikeHandler } from '../[id]/[[...pageParams]]/_components';
import { Badge, Box, Flex, Modal, Text } from '@mantine/core';
import { editCaptionModalTitle, responsiveImage } from '../styles.css';
import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { useDisclosure } from '@mantine/hooks';
function GalleryFolioImage({
  fi,
  i
}: {
  fi: FolioItem;
  i: number;
}) {
  const {
    isSignedIn
  } = useAppAuth();
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const {
    solidHeart,
    handleLikePhotoOnClick,
    handleUnLikeOnClick
  } = useLikeHandler({
    hasLiked: fi.hasLiked
  });
  const [selectedImage, setSelectedImage] = useState<FolioItem | undefined>(undefined);
  const [opened, {
    open: captionOpen,
    close: captionClose
  }] = useDisclosure(false);
  function handleBadgeOnClick(item: FolioItem) {
    setSelectedImage(item);
    captionOpen();
  }
  return <Box style={{
    marginBottom: '16px',
    breakInside: 'avoid' // Prevent images from breaking into new columns
  }} data-sentry-element="Box" data-sentry-component="GalleryFolioImage" data-sentry-source-file="BusinessFolioDesktop.tsx">
      {/* Container for the image with relative positioning */}
      <Box style={{
      position: 'relative',
      width: '100%',
      maxWidth: '550px',
      // Set the maximum width for all images
      margin: '0 auto' // Center the image
    }} data-sentry-element="Box" data-sentry-source-file="BusinessFolioDesktop.tsx">
        <SafeImage src={fi.file.url} alt={`Image ${i + 1}`} width={500} // Set a default width
      height={750} // Set a default height, aspect ratio will be maintained by CSS
      className={responsiveImage}
      // onClick={() => {
      //   setIndex(index)
      //   setOpen(true)
      // }}
      breakpoints={{
        xs: 100,
        sm: 70,
        md: 50,
        lg: 30,
        xl: 20
      }} data-sentry-element="SafeImage" data-sentry-source-file="BusinessFolioDesktop.tsx" />
        <Flex style={{
        position: 'absolute',
        // Absolute positioning inside the image container
        bottom: '12px',
        // Always stays 8px from the bottom of the image
        left: '12px',
        // Always stays 12px from the left side of the image
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        borderRadius: '8px',
        // Rounded corners
        padding: '4px'
      }} align="center" gap="sm" data-sentry-element="Flex" data-sentry-source-file="BusinessFolioDesktop.tsx">
          {isCurrentUserProfile || !isSignedIn ? null : solidHeart ? <Flex align="center" gap="xs">
              <HeartIconSolid height={20} color="black" onClick={() => isSignedIn ? handleUnLikeOnClick(fi.id) : undefined} style={{
            cursor: isSignedIn ? 'pointer' : undefined
          }} />
            </Flex> : <Flex align="center" gap="xs">
              <HeartIcon height={20} color="black" onClick={() => handleLikePhotoOnClick(fi.id)} style={{
            cursor: 'pointer'
          }} />
            </Flex>}
        </Flex>
        {isCurrentUserProfile ? <Flex justify="space-between">
            <Flex gap="5px" align="center">
              <HeartIcon height={16} />
              <Text size="sm">{fi.caption?.length ? fi.caption : ''}</Text>
            </Flex>
            <Badge color="black" size="lg" radius="md" mt="2px" style={{
          cursor: 'pointer'
        }} onClick={() => handleBadgeOnClick(fi)}>
              {fi.caption?.length ? 'EDIT CAPTION' : 'ADD CAPTION'}
            </Badge>
          </Flex> : null}
        <Modal opened={opened} onClose={() => {
        setSelectedImage(undefined);
        captionClose();
      }} withCloseButton={false} centered title="GIVE YOUR IMAGE A CAPTION" classNames={{
        title: editCaptionModalTitle
      }} data-sentry-element="Modal" data-sentry-source-file="BusinessFolioDesktop.tsx">
          <CaptionModalInner selectedImage={selectedImage} setSelected={setSelectedImage} closeCaption={captionClose} data-sentry-element="CaptionModalInner" data-sentry-source-file="BusinessFolioDesktop.tsx" />
        </Modal>{' '}
        <Modal opened={opened} onClose={() => {
        setSelectedImage(undefined);
        captionClose();
      }} withCloseButton={false} centered title="GIVE YOUR IMAGE A CAPTION" classNames={{
        title: editCaptionModalTitle
      }} data-sentry-element="Modal" data-sentry-source-file="BusinessFolioDesktop.tsx">
          <CaptionModalInner selectedImage={selectedImage} setSelected={setSelectedImage} closeCaption={captionClose} data-sentry-element="CaptionModalInner" data-sentry-source-file="BusinessFolioDesktop.tsx" />
        </Modal>
      </Box>
    </Box>;
}
export default function BusinessFolioDesktop() {
  const {
    account
  } = useContext(PeerAccountContext);
  return <>
      <Box w="100%" pt="90px" style={{
      columnCount: 4,
      columnGap: '12px',
      paddingBottom: '0px',
      paddingTop: '16px',
      justifyContent: 'center'
    }} data-sentry-element="Box" data-sentry-source-file="BusinessFolioDesktop.tsx">
        {account?.folioItems?.map((fi, i) => <GalleryFolioImage fi={fi} key={fi.id} i={i} />)}
      </Box>
    </>;
}