'use client';

import { PlusIcon } from '@heroicons/react/24/outline';
import { Divider, Flex, Stack, Text } from '@mantine/core';
import { useClient, useMutation } from '@urql/next';
import { Suspense, useContext, useEffect, useState } from 'react';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { AppLayoutContext, IdentityContext, ImageUploader } from '~/app/_components';
import { type AccountUpdateInput, type FileCreateInput, FileType } from '~/generated/graphql';
import { GalleryGrid } from './gallery';
import { usePathname } from 'next/navigation';
import { appTrace } from '~/utils';

// export const FOLIO_ITEMS_LIMIT = 9

export function AddFolio() {
  const {
    mobile
  } = useContext(AppLayoutContext);

  // const folioImagesLeft = FOLIO_ITEMS_LIMIT - (currentAccount?.folioItems?.length || 0)
  // const maxFolioImagesReached = folioImagesLeft === 0

  const [folioCount, setFolioCount] = useState(1);
  useEffect(() => {
    console.log('count', folioCount);
  }, [folioCount]);
  return <>
      <Stack gap="0px" mt={mobile ? '30px' : '40px'} data-sentry-element="Stack" data-sentry-source-file="AddFolio.tsx">
        {/* {Array.from({ length: folioCount }).map((_, index) => ( */}
        {Array.from({
        length: 1
      }).map((_, index) => <AddAnotherFolio key={index} index={index} folioCount={folioCount} />)}
      </Stack>

      {/* <StyledButton
        text="ADD ANOTHER FOLIO"
        fz={mobile ? '13px' : '15.6px'}
        type="black"
        width="100%"
        highHeight={!mobile}
        veryHighHeight={!mobile}
        onClick={() => setFolioCount(folioCount + 1)}
       /> */}
    </>;
}
function AddAnotherFolio({
  index,
  folioCount
}: {
  index: number;
  folioCount: number;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [open, setOpen] = useState(false);
  const [caption, setCaption] = useState<string | undefined>('');
  const [folioFile, setFolioFile] = useState(null as null | FileCreateInput);
  const plusIconHeight = mobile ? 25 : 38;
  const textFontWeight = mobile ? 500 : 400;
  const pn = usePathname();
  const isOnboarding = pn.includes('/onboarding');
  const [, update] = useMutation(updateCurrentAccount);
  const client = useClient();
  const showDividerBetweenFolios = folioCount > 1 && index < folioCount - 1;
  console.log('index, foliocount, divider', index, folioCount, showDividerBetweenFolios);
  useEffect(() => {
    if (!folioFile) return;
    const account: AccountUpdateInput = {
      folioItems: {
        create: [{
          caption: caption,
          file: {
            create: folioFile
          }
        }]
      }
    };
    setFolioFile(null);
    update({
      account
    });
  }, [folioFile, client, update, caption]);
  const handleClick = () => {
    appTrace('handleClick');
    setOpen(true);
  };
  return <Flex direction="column" data-sentry-element="Flex" data-sentry-component="AddAnotherFolio" data-sentry-source-file="AddFolio.tsx">
      <Text size={mobile ? '13px' : '16px'} fw={mobile ? 600 : 600} mb={mobile ? '5px' : '5px'} data-sentry-element="Text" data-sentry-source-file="AddFolio.tsx">
        FOLIO
      </Text>
      {/* <CommonTextField
        required
        size="md"
        placeholder="Label your folio"
        name="agency"
        // pt={index === 0 ? '0px' : '0px'}
        py={mobile ? '8px' : '10px'}
       /> */}

      {!currentAccount?.folioItems?.length ? <Text size={mobile ? '10px' : '10px'} fw={400}>
          Add at least 1 image to make your folio visible.
        </Text> : mobile ? null : <Text size={mobile ? '10px' : '10px'} fw={400} mb="10px">
          Drag images to reorder them.
        </Text>}

      <Suspense data-sentry-element="Suspense" data-sentry-source-file="AddFolio.tsx">
        <GalleryGrid data-sentry-element="GalleryGrid" data-sentry-source-file="AddFolio.tsx" />
      </Suspense>

      <ImageUploader addCaption={!isOnboarding} setCaption={!isOnboarding ? setCaption : undefined}
    // maxNumberOfFiles={folioImagesLeft}
    fileType={FileType.Folio} open={open} onChange={setFolioFile} onClose={() => setOpen(false)} shouldCloseAfterFinish={true} data-sentry-element="ImageUploader" data-sentry-source-file="AddFolio.tsx" />
      <Flex direction="column" w="100%" style={{
      border: '1px dotted black'
    }} h={mobile ? '100px' : '172px'} align="center" justify="center" mt={mobile ? '8px' : '10px'} mb="30px" onClick={handleClick} data-testid="add-folio-button-image" data-sentry-element="Flex" data-sentry-source-file="AddFolio.tsx">
        <PlusIcon height={plusIconHeight} data-testid="add-folio-image" data-sentry-element="PlusIcon" data-sentry-source-file="AddFolio.tsx" />
        {<Text ta="center" size="lg" fw={textFontWeight} mt="10px">
            Add images from your device.
          </Text>}
      </Flex>
      {showDividerBetweenFolios ? <Divider mb="40px" /> : null}
    </Flex>;
}