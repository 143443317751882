'use client';

import 'swiper/css';
import { Flex } from '@mantine/core';
import type { ContextModalProps } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Textarea } from 'react-hook-form-mantine';
import { AppLayoutContext, StyledButton } from '~/app/_components';
import { AcceptOrCreateFriendship } from '~/app/_graphql/AcceptOrCreateFriendship';
import type { Account } from '~/generated/graphql';
import { appTrace } from '~/utils';
import { inviteModalDescription, inviteModalInput, inviteModalInputWrapper } from '../desktop/styles.css';
export interface MessageFormValues {
  message: string;
}
export function InviteModal({
  context,
  id,
  innerProps
}: ContextModalProps<{
  account: Account;
}>) {
  const [, executeUpdate] = useMutation(AcceptOrCreateFriendship);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const messageForm = useForm<MessageFormValues>({
    defaultValues: {
      message: ''
    }
  });
  const onFormSubmit = messageForm.handleSubmit(async data => {
    const message = {
      content: data.message
    };
    const result = await executeUpdate({
      recipientId: innerProps.account.id,
      message: message.content
    });
    if (result.data?.acceptOrCreateFriendship?.__typename === 'Account') {
      notifications.show({
        title: 'Invitation sent',
        message: 'The user received your invitation.'
      });
    } else if (result?.data?.acceptOrCreateFriendship?.__typename === 'Error') {
      appTrace('Could not send the message', {
        result
      });
      notifications.show({
        title: 'Message cound not be sent',
        message: result.data.acceptOrCreateFriendship.message,
        color: 'red'
      });
    }
  });
  function handleSendInvite() {
    onFormSubmit();
    context.closeModal(id);
  }
  function handleCloseInvite() {
    context.closeModal(id);
  }
  return <form onSubmit={onFormSubmit} data-sentry-component="InviteModal" data-sentry-source-file="InviteModal.tsx">
      <Flex direction="column" mih={mobile ? '' : '20vh'} justify="space-between" gap="md" data-sentry-element="Flex" data-sentry-source-file="InviteModal.tsx">
        <Textarea
      // p={mobile ? 'xs' : '60px'}
      data-autofocus name="message" description="SEND A MESSAGE WITH YOUR INVITE" placeholder="Write Something..." control={messageForm.control} data-testid="invite-textarea" classNames={{
        input: inviteModalInput,
        description: inviteModalDescription,
        wrapper: inviteModalInputWrapper
      }} data-sentry-element="Textarea" data-sentry-source-file="InviteModal.tsx" />
        <Flex w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="InviteModal.tsx">
          <StyledButton testId="invite-modal-send-button" type="passive" text="BACK" size={mobile ? 'large' : 'small'} onClick={handleCloseInvite} data-sentry-element="StyledButton" data-sentry-source-file="InviteModal.tsx" />
          <StyledButton testId="invite-modal-send-button" type="primary" text="SEND INVITE" size={mobile ? 'large' : 'small'} onClick={handleSendInvite} data-sentry-element="StyledButton" data-sentry-source-file="InviteModal.tsx" />
        </Flex>
      </Flex>
    </form>;
}