import "../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(accounts)%2Faccounts%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62VS3PaMBCA7%2FkV6oGZ9uCMoeERcmmSU2aa6aHpmdELW0WWPNIacDv898rG%2BAkG0hxZwfetdrXLrYVUcruIQw06wFJyk%2F6kRktJsFkshhtI09XGR39vENoIBuEcDf14iz6JKNYGsIIHdxJhEwjlgY7d8did14JEA%2BiojO9ubguliHDAn7UCLBSvXMOmyx8cYbVRhttYKyvW%2FCWDlqxRnYUT0Bkr5CIIofoc4a13iM38dXiItVLQ5Den4C2F%2BxrdZ11PgeIYhFZPmNX8X3M%2FwXQVGJ0o5lEttZkjIl0og9LE2CwQa6GAmzqQJNbVxdpHSt1P4Q2T75hwWbLvcvbSZeJtiuynvp9BiTaMG89gJhLr7tRq11nFIwVXyVI0PikqbrMJBfCrxIzblXss%2Fd7Jhd5z2F8KN8HTc%2BCiPRXYTYfir5rhqvyzCmLFHz5Ho7vOWLQVJ6pR0V81ETKfCa6gVN3nqsMTvfcHLQ0TNpY4naOl5Ns%2B8JNmaUnFDepsPDiRXWu29qQfCdRGluQoV7wkUl5ee1eOhyoU4GwvjHpnds99UfVVQHNueT2hpHuuHpF6Pzz9O2J2ga40sWNLp70omhtg3RxXzgQ873dAXvE3ATU%2Bb7%2BWYXs4Ln4tbVGjqcuOZ9TvmXQ83yJnwMhSw7lCWDH0OXJlLWoznbjEv%2BSW6xZVM6tJJ6vdeeLRvXQN94q18%2F%2FYE2vnPPh4C6o%2FpA9qQfdhfEwL%2BrnvbsF7sBe14Bh49w8djt4zFwkAAA%3D%3D%22%7D"
export var businessAccountTabLabel = 'styles_businessAccountTabLabel__1wtyykw4';
export var businessAccountTabLabelActive = 'styles_businessAccountTabLabelActive__1wtyykw5';
export var captionBadge = 'styles_captionBadge__1wtyykw3';
export var desktopAccountTabLabelActive = 'styles_desktopAccountTabLabelActive__1wtyykw6';
export var desktopAccountTabLabelUnactive = 'styles_desktopAccountTabLabelUnactive__1wtyykw7';
export var editCaptionModalBody = 'styles_editCaptionModalBody__1wtyykwf';
export var editCaptionModalTitle = 'styles_editCaptionModalTitle__1wtyykwe';
export var imageContainer = 'styles_imageContainer__1wtyykw1';
export var phoneModal = 'styles_phoneModal__1wtyykw8';
export var phoneModalMobileBody = 'styles_phoneModalMobileBody__1wtyykwa';
export var phoneModalMobileContent = 'styles_phoneModalMobileContent__1wtyykw9';
export var photogalleryScrollbar = 'styles_photogalleryScrollbar__1wtyykw0';
export var responsiveImage = 'styles_responsiveImage__1wtyykw2';
export var responsiveImageMobile = 'styles_responsiveImageMobile__1wtyykwd';
export var responsiveImageMobileInner = 'styles_responsiveImageMobileInner__1wtyykwc';
export var responsiveImageMobileOuter = 'styles_responsiveImageMobileOuter__1wtyykwb';