'use client';

import { Text, Title, Flex } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { AppLayoutContext, PeerAccountContext, RemoteConfigContext, StyledButton } from '~/app/_components';
import { UserGender } from '~/generated/graphql';
export default function About() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    professions
  } = useContext(RemoteConfigContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [showImperial, setShowImperial] = useState(false);
  const [imperialHeight, setImperialHeight] = useState<string>('');
  const isModel = account.professions?.some(prof => {
    const profession = professions.find(p => p.id === prof.id);
    return profession?.name.match('Model');
  });
  function changeMetrics() {
    showImperial ? setShowImperial(false) : setShowImperial(true);
  }
  useEffect(() => {
    if (!account.personalDetails?.height) return;
    const totalInches = account.personalDetails?.height / 2.54; // Convert cm to inches
    const feet = Math.floor(totalInches / 12); // Get whole feet
    const inches = Math.round(totalInches % 12); // Get remaining inches

    setImperialHeight(`${feet}ft ${inches}in`);
  }, [account.personalDetails?.height]);
  function renderHeight() {
    if (!account.personalDetails?.height) return '-';
    if (showImperial) return imperialHeight;
    return `${account.personalDetails?.height} cm`;
  }
  const dressSizes = [{
    value: 'XS',
    label: 'XS (Extra Small): 0-2'
  }, {
    value: 'S',
    label: 'S (Small): 4-6'
  }, {
    value: 'M',
    label: 'M (Medium): 8-10'
  }, {
    value: 'L',
    label: 'L (Large): 12-14'
  }, {
    value: 'XL',
    label: 'XL (Extra Large): 16-18'
  }, {
    value: 'XXL',
    label: '2XL (Extra Extra Large): 18-20'
  }];
  const pantSizes = [{
    value: 'XS',
    label: 'XS: 26-28 inches'
  }, {
    value: 'S',
    label: 'S: 28-30 inches'
  }, {
    value: 'M',
    label: 'M: 30-32 inches'
  }, {
    value: 'L',
    label: 'L: 32-34 inches'
  }, {
    value: 'XL',
    label: 'XL: 34-36 inches'
  }, {
    value: 'XXL',
    label: 'XXL: 38 inches'
  }, {
    value: 'XXXL',
    label: '3XL: 40 inches'
  }, {
    value: 'XXXXL',
    label: '4XL: 42 inches'
  }, {
    value: 'XXXXXL',
    label: '5XL: 44 inches'
  }, {
    value: 'XXXXXXL',
    label: '6XL: 46 inches'
  }];
  function renderSize() {
    if (account.profile?.gender === UserGender.Female) {
      return dressSizes.find(s => s.value === account.personalDetails?.dressSize)?.label || '-';
    }
    if (account.profile?.gender === UserGender.Male) {
      return pantSizes.find(s => s.value === account.personalDetails?.pantSize)?.label || '-';
    }
    return dressSizes.find(s => s.value === account.personalDetails?.dressSize)?.label || '-';
  }
  return <Flex w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-component="About" data-sentry-source-file="About.tsx">
      <Flex w="75%" direction="column" data-sentry-element="Flex" data-sentry-source-file="About.tsx">
        <Title size={26} mt="50px" mb="xl" tt="uppercase" data-sentry-element="Title" data-sentry-source-file="About.tsx">
          About {account.profile?.name}
        </Title>
        <Text size="lg" style={{
        maxHeight: !mobile ? '50vh' : undefined,
        // Limit height to 50% of the viewport
        overflowY: !mobile ? 'auto' : undefined,
        // Enable scrolling if the content exceeds the height
        columns: !mobile ? '2' : undefined,
        // Allow up to 3 columns
        columnGap: !mobile ? '1.5rem' : undefined,
        // Adjust the gap between columns
        columnFill: !mobile ? 'auto' : undefined // Fill one column before moving to the next
      }} data-sentry-element="Text" data-sentry-source-file="About.tsx">
          {account?.profile?.about}
        </Text>
      </Flex>

      {isModel ? <Flex w="25%" direction="column">
          <Title size={26} mt="50px" mb="xl" tt="uppercase">
            Measurements
          </Title>
          <Flex w="75%" justify="space-between" align="center" mb="md">
            <Text size="16px" fw={600} c="dimmed">
              HEIGHT
            </Text>
            <Text size="14px">{renderHeight()}</Text>
          </Flex>

          <Flex w="75%" justify="space-between" align="center">
            <Text size="16px" fw={600} c="dimmed">
              {account.profile?.gender === UserGender.Female ? "WOMEN'S SIZE" : "MEN'S SIZE"}
            </Text>
            <Text size="14px">{renderSize()}</Text>
          </Flex>

          <Flex w="100%" gap="sm" mt="xl">
            <StyledButton text="METRIC" type={showImperial ? 'passive' : 'black'} onClick={changeMetrics} />
            <StyledButton text="IMPERIAL" type={showImperial ? 'black' : 'passive'} onClick={changeMetrics} />
          </Flex>
        </Flex> : null}
    </Flex>;
}