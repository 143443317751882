'use client';

import { useContext } from 'react';
import { SafeImage } from '../../images';
import { AppLayoutContext } from '../../context';
export function HeaderLogoOnboarding() {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <SafeImage src="/imgs/layout/domeSphereLogo3.webp" priority={true} alt="Dome logo" height={mobile ? 54 : 30} width={mobile ? 119 : 66} data-sentry-element="SafeImage" data-sentry-component="HeaderLogoOnboarding" data-sentry-source-file="LogoOnboarding.tsx" />;
}