/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
'use client';

import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useContext } from 'react';
import { AppLayoutContext } from '~/app/_components';
import { reviewModal } from '../styles.css';
export function ContactUs() {
  const [opened, {
    close,
    open
  }] = useDisclosure(false);
  // const [feedback, setFeedback] = useState<string | undefined>('')
  // const { currentAccount } = useContext(IdentityContext)
  // const pn = usePathname()

  const {
    mobile
  } = useContext(AppLayoutContext);

  // const [, executeSendMessage] = useMutation(SendMessage)

  // async function sendFeedback() {
  //   const result = await executeSendMessage({
  //     recipientId: sendUserInteractionsTo,
  //     message: {
  //       content: `Feedback: ${feedback}. Account: ${currentAccount?.id}. Route: ${pn}`,
  //     },
  //   })

  //   if (result.data?.sendMessage?.__typename === 'Account') {
  //     close()
  //     notifications.show({
  //       title: 'Feedback Sent',
  //       message: 'Thank you! We appreciate your feedback.',
  //     })
  //   } else if (result?.data?.sendMessage?.__typename === 'Error') {
  //     appTrace('Could not accept the friendship request', { result })
  //     notifications.show({
  //       title: 'Something went wrong, try again or contact us at hello@createwithdome.com',
  //       message: result.data.sendMessage.message,
  //       color: 'warning',
  //     })
  //   }
  // }

  if (mobile) return null;
  return <>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div style={{
      zIndex: '100',
      position: 'fixed',
      bottom: mobile ? '160px' : '90px',
      right: '0px',
      color: '#48FFB9',
      cursor: 'pointer',
      height: '140px',
      width: mobile ? '30px' : '40px',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bolder',
      fontSize: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    }} onClick={open}>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          D
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          O
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          M
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          &nbsp;
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          F
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          D
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          B
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          A
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          C
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          K
        </span>
      </div>

      <Modal opened={opened} onClose={close} size="auto" radius="lg"
    // h="75vh"
    classNames={{
      // root: reviewModal,
      // content: reviewModal,
      body: reviewModal
    }} data-sentry-element="Modal" data-sentry-source-file="ContactUs.tsx">
        <iframe title="Dome review form" src="https://docs.google.com/forms/d/e/1FAIpQLSenvetRwSR3V72yoRm8aOzm68QtR8xNBIFLjBx7L8ujYoiULw/viewform?embedded=true" style={{
        border: 'none',
        overflowY: 'clip',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none'
      }} width={mobile ? '390' : '800'} height="100%"
      // frameborder="0"
      // marginheight="0"
      // marginwidth="0"
      >
          Wait…
        </iframe>

        {/* <Text c="dimmed">Ideas for Dome?</Text>
         <Text c="dimmed">Is something bugging or too slow?</Text>
         <Text c="dimmed">Let us know below!</Text>
         <Textarea py="md" minRows={10} onChange={(e) => setFeedback(e.target.value)} />
         <StyledButton text="Submit" type="primary" width="100%" onClick={sendFeedback} /> */}
      </Modal>
    </>;
}