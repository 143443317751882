import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(me)%2F_components%2Fcommon%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61VTY%2FaMBC9769wt5flYBRCQrdBldotXalSD9Vqb1WFTDwJbh3bchw%2BWvHf1%2Flik0AA0R44hJn35s3zeDxMzZZDOo9BUNCfpTBa8vncXW234chBf28QCiWXOkALTsLf05vdzbAFeZLS1PmjIn9h82ItM0FxBV0vmYFpHpLaQgI0UhuUSs5ozYqQIpQyEQfIUZv8OyE6ZqL67Bb9KlS2r%2BoWVXvyDWxMkd3UOS4QlKWKk22AIg4b9IYlSmpDhDkhtJNVacaaxUtjk33V5YmsoXgNZdxznKNlsCaUZWmA7jv41zZS4BCamVyLh8wYKepOvMNOct5fWWpYtLX%2BCwPClAFsreuhb7n0vezqKRdd1%2FGLOp1%2B3RNWVyQ1flJOxtnjxwtp20sC5J%2FgnhEDNfG7c8S1xaVkIQUcMd6zOKf6eeX8hZlO89lVklkPda%2BYZ5bsxdxfKIZD1K%2Bl0lBrahZWWkaMwyMDTgsB38gC9rf1fVG9mLiU%2FQErYNKGZ4pLQj%2BtiCH6wexnaFHgjCYiZYZJe4e6Nxj5jpOkl1AFS7kC3bMGVkTfYZzY2WMCyj9xrMkWjwbTKxU8Wn9lQ0B4cS%2F7vdaryhtcUK%2FR8Dk%2B%2F4ouG%2Buuddb0gs18BPWD2gPDJDRsBR9ujc7g9meTSTHRIoqkTr5oLfWzHfmaBZqItxHxXd%2BdtifPGfoT19eQNMl4LmUGaaiZyvuv%2BaLXuW1syq6MViPxIWRSQj4mQBlBdwnZ4DWjZllE1GZQIC54FexLwgRe1kLcg5V%2BcMU68V1fnf692qZ0ywXU5DltXRtevZ%2F1i9jeqG3aHl%2FbfONzfA3TbXhv%2Buhq033njOne%2Fzfd%2BzfT3R6Txlea7g8n5fY%2Bxbl7Af0qbBy9CQAA%22%7D"
export var contactInfoDescr = 'styles_contactInfoDescr__2vyyc1a';
export var formErrorText = 'styles_formErrorText__2vyyc1e';
export var formLabel = 'styles_formLabel__2vyyc1g';
export var genderControl = 'styles_genderControl__2vyyc10';
export var genderInput = 'styles_genderInput__2vyyc12';
export var genderInputLabel = 'styles_genderInputLabel__2vyyc1d';
export var genderRoot = 'styles_genderRoot__2vyyc11';
export var labelDescription = 'styles_labelDescription__2vyyc1f';
export var profileFieldInputLabel = 'styles_profileFieldInputLabel__2vyyc19';
export var selectDownButton = 'styles_selectDownButton__2vyyc14';
export var textInputRoot = 'styles_textInputRoot__2vyyc13';
export var textInputRootDate = 'styles_textInputRootDate__2vyyc17';
export var textInputRootPadding = 'styles_textInputRootPadding__2vyyc16';
export var textInputRootPaddingRight = 'styles_textInputRootPaddingRight__2vyyc15';
export var textInputRootTime = 'styles_textInputRootTime__2vyyc18';
export var uploadAvatarBtn = 'styles_uploadAvatarBtn__2vyyc1b';
export var uploadFolioBtn = 'styles_uploadFolioBtn__2vyyc1c';