'use client';

import { useAuth0 } from '@auth0/auth0-react';
import { usePathname, useRouter } from 'next/navigation';
import type React from 'react';
import { useContext, useEffect } from 'react';
import { appTrace } from '~/utils';
import { AuthActorContext } from './AuthProvider';
import { useAppAuth } from './hooks';
import { useSelector } from '@xstate/react';
import { Route } from 'next';
function AuthRouterRedirector({
  children
}: React.PropsWithChildren) {
  const {
    isStableAuthState
  } = useAppAuth();
  const pathname = usePathname();
  const router = useRouter();
  // NOTE: Everything below just makes sure that the user lands on the correct
  // /onboarding page if they are not onboarded
  //
  // NOTE: Once we redirect the user to /onboarding the state machine there needs to
  // route to the correct subpath
  const {
    needsOnboarding,
    allowsOnboardingCompletion,
    isSignedIn
  } = useAppAuth();
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const isSuspended = useSelector(authActor, state => {
    return state.matches('registration.suspended');
  });
  useEffect(() => {
    if (!isStableAuthState) return;
    if (isSuspended && !pathname.startsWith('/suspended')) {
      appTrace('authRouter: user suspended, redirecting');
      router.push('/suspended');
      return;
    }

    // NOTE: needsOnboading could be true for a short period of time
    // after onboading is complete and the redirect to / is already done
    if (needsOnboarding && !pathname.startsWith('/onboarding')) {
      appTrace('authRouter: needs onboarding, redirecting');
      router.push('/onboarding');
      return;
    }
    if (pathname.startsWith('/start') && isSignedIn) {
      appTrace('authRouter: signedIn and onboarded, redirect to /');
      router.push('/');
      return;
    }
    if (isSignedIn) {
      appTrace('authRouter: user signedIn and does not need onboarding, noop');
      return;
    }

    // if (!pathname.startsWith('/start') && !pathname.startsWith('/onboarding')) {
    //   appTrace('authRouter: unauthenticated, redirect to /start')
    //   router.push('/start')
    //   return
    // }

    appTrace('authRouter: stable auth state, no redirect', {
      needsOnboarding
    });
  }, [isSuspended, needsOnboarding, pathname, isStableAuthState]);

  // NOTE: This part makes sure when user is fully onboarded we redirect them to the
  // root url. This redirect must happen outside of the onboarding actor
  // because we need to wait for identity context to be fully loaded with the Active
  // user state
  //
  useEffect(() => {
    if (!isStableAuthState) return;
    if (allowsOnboardingCompletion && pathname.startsWith('/onboarding')) {
      appTrace('authRouter: onboarding finished, redirecting');
      const redirectUrl = localStorage.getItem('redirect_to');
      if (redirectUrl) {
        localStorage.removeItem('redirect_to');
        router.push(redirectUrl as Route);
      } else {
        router.push('/help');
      }
    }
  }, [allowsOnboardingCompletion, pathname, isStableAuthState]);
  return <>{children}</>;
}
export function AuthRouter({
  children
}: React.PropsWithChildren) {
  const {
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently
  } = useAuth0();
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  useEffect(() => {
    if (!isAuthenticated) return;
    appTrace('authRouter: getAccessTokenSilently triggered');
    getAccessTokenSilently().then(token => {
      appTrace('authRouter: getAccessTokenSilently update');
      authActor.send({
        type: 'AUTH0_TOKEN_UPDATE',
        data: {
          token
        }
      });
    }).catch(_err => {
      appTrace('authRouter: getAccessTokenSilently failed');
      authActor.send({
        type: 'AUTH0_TOKEN_UNAVAILABLE'
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error, getAccessTokenSilently]);
  useEffect(() => {
    if (isLoading || isAuthenticated) return;
    appTrace('authRouter: unauthenticated provider', {
      isAuthenticated,
      isLoading,
      error
    });
    authActor.send({
      type: 'AUTH0_TOKEN_UNAVAILABLE'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error]);
  return <AuthRouterRedirector data-sentry-element="AuthRouterRedirector" data-sentry-component="AuthRouter" data-sentry-source-file="AuthRouter.tsx">{children}</AuthRouterRedirector>;
}