'use client';

import { Flex, Grid, Title } from '@mantine/core';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonSelectField, ProfileFormValues } from '~/app/(me)/_components/common/form';
import { AppLayoutContext, IdentityContext, RemoteConfigContext } from '~/app/_components';
import { labelDescription, textInputRoot } from '../styles.css';
import { UserGender } from '~/generated/graphql';
export function MeasurementsFields() {
  const {
    professions
  } = useContext(RemoteConfigContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const profileForm = useFormContext<ProfileFormValues>();
  const {
    currentAccount
  } = useContext(IdentityContext);

  // Subscribe to the field's value changes
  const selectedProfessions = useWatch({
    control: profileForm.control,
    name: 'professions',
    defaultValue: []
  });
  const isModel = selectedProfessions.some((selectedProfession: string) => {
    const profession = professions.find(p => p.id === selectedProfession);
    return profession?.name.match('Model');
  });
  const useMetric = !navigator.language.startsWith('en-US');
  const useMaleSize = currentAccount?.profile?.gender === UserGender.Male;
  // console.log(useMetric ? 'Metric system' : 'Imperial system')

  function renderSizeTitle() {
    if (useMaleSize) return "MEN'S SIZE";
    if (currentAccount?.profile?.gender === UserGender.Female) return "WOMEN'S SIZE";
    // biome-ignore lint/style/noUselessElse: <explanation>
    else return 'SIZE';
  }
  function renderSizes() {
    if (useMaleSize) return pantSizes;
    // biome-ignore lint/style/noUselessElse: <explanation>
    else return dressSizes;
  }

  // TODO make reusable
  const dressSizes = [{
    value: 'XS',
    label: 'XS (Extra Small): 0-2'
  }, {
    value: 'S',
    label: 'S (Small): 4-6'
  }, {
    value: 'M',
    label: 'M (Medium): 8-10'
  }, {
    value: 'L',
    label: 'L (Large): 12-14'
  }, {
    value: 'XL',
    label: 'XL (Extra Large): 16-18'
  }, {
    value: 'XXL',
    label: '2XL (Extra Extra Large): 18-20'
  }];
  const pantSizes = [{
    value: 'XS',
    label: 'XS: 26-28 inches'
  }, {
    value: 'S',
    label: 'S: 28-30 inches'
  }, {
    value: 'M',
    label: 'M: 30-32 inches'
  }, {
    value: 'L',
    label: 'L: 32-34 inches'
  }, {
    value: 'XL',
    label: 'XL: 34-36 inches'
  }, {
    value: 'XXL',
    label: 'XXL: 38 inches'
  }, {
    value: 'XXXL',
    label: '3XL: 40 inches'
  }, {
    value: 'XXXXL',
    label: '4XL: 42 inches'
  }, {
    value: 'XXXXXL',
    label: '5XL: 44 inches'
  }, {
    value: 'XXXXXXL',
    label: '6XL: 46 inches'
  }];
  return <Flex direction="column" style={{
    display: isModel ? undefined : 'none'
  }} data-sentry-element="Flex" data-sentry-component="MeasurementsFields" data-sentry-source-file="MeasurementsFields.tsx">
      <Title size={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} tt="uppercase" mb={mobile ? '0px' : '20px'} data-sentry-element="Title" data-sentry-source-file="MeasurementsFields.tsx">
        Measurements
      </Title>
      <Grid justify="flex-start" data-sentry-element="Grid" data-sentry-source-file="MeasurementsFields.tsx">
        <Grid.Col span={mobile ? 12 : 6} data-sentry-element="unknown" data-sentry-source-file="MeasurementsFields.tsx">
          <CommonSelectField name="personalDetails.height" size="md" label="HEIGHT" placeholder="Select" data={useMetric ? metricSelectMenu : imperialSelectMenu} clearable classNames={{
          description: labelDescription,
          input: textInputRoot
        }} data-sentry-element="CommonSelectField" data-sentry-source-file="MeasurementsFields.tsx" />
        </Grid.Col>
        <Grid.Col span={mobile ? 12 : 6} data-sentry-element="unknown" data-sentry-source-file="MeasurementsFields.tsx">
          <CommonSelectField name={useMaleSize ? 'personalDetails.pantSize' : 'personalDetails.dressSize'} size="md" label={renderSizeTitle()} placeholder="Select" data={renderSizes()} clearable classNames={{
          description: labelDescription,
          input: textInputRoot
        }} data-sentry-element="CommonSelectField" data-sentry-source-file="MeasurementsFields.tsx" />
        </Grid.Col>
        {/* {measurements.map((m) => (
              <Grid.Col span={mobile ? 6 : 3} key={m} px={mobile ? 'lg' : 'sm'}>
                <CommonMeasurementSelectField name={m as any} label={m} {...measurementSelectProps} />
              </Grid.Col>
            ))} */}
      </Grid>
    </Flex>;
}
const imperialSelectMenu = [{
  value: '137',
  label: `4'6"`
}, {
  value: '140',
  label: `4'7"`
}, {
  value: '142',
  label: `4'8"`
}, {
  value: '145',
  label: `4'9"`
}, {
  value: '147',
  label: `4'10"`
}, {
  value: '150',
  label: `4'11"`
}, {
  value: '152',
  label: `5'0"`
}, {
  value: '155',
  label: `5'1"`
}, {
  value: '157',
  label: `5'2"`
}, {
  value: '160',
  label: `5'3"`
}, {
  value: '163',
  label: `5'4"`
}, {
  value: '165',
  label: `5'5"`
}, {
  value: '168',
  label: `5'6"`
}, {
  value: '170',
  label: `5'7"`
}, {
  value: '173',
  label: `5'8"`
}, {
  value: '175',
  label: `5'9"`
}, {
  value: '178',
  label: `5'10"`
}, {
  value: '180',
  label: `5'11"`
}, {
  value: '183',
  label: `6'0"`
}, {
  value: '185',
  label: `6'1"`
}, {
  value: '188',
  label: `6'2"`
}, {
  value: '191',
  label: `6'3"`
}, {
  value: '193',
  label: `6'4"`
}, {
  value: '196',
  label: `6'5"`
}, {
  value: '198',
  label: `6'6"`
}, {
  value: '201',
  label: `6'7"`
}, {
  value: '203',
  label: `6'8"`
}, {
  value: '206',
  label: `6'9"`
}, {
  value: '208',
  label: `6'10"`
}, {
  value: '211',
  label: `6'11"`
}, {
  value: '213',
  label: `7'0"`
}];
const metricSelectMenu = Array.from({
  length: 213 - 137 + 1
}, (_, i) => {
  const cm = 137 + i;
  return {
    value: cm.toString(),
    label: `${cm} cm`
  };
});

// const metricSelectMenu = [
//   { value: '137', label: '137 cm' },
//   { value: '140', label: '140 cm' },
//   { value: '142', label: '142 cm' },
//   { value: '145', label: '145 cm' },
//   { value: '147', label: '147 cm' },
//   { value: '150', label: '150 cm' },
//   { value: '152', label: '152 cm' },
//   { value: '155', label: '155 cm' },
//   { value: '157', label: '157 cm' },
//   { value: '160', label: '160 cm' },
//   { value: '163', label: '163 cm' },
//   { value: '165', label: '165 cm' },
//   { value: '168', label: '168 cm' },
//   { value: '170', label: '170 cm' },
//   { value: '173', label: '173 cm' },
//   { value: '175', label: '175 cm' },
//   { value: '178', label: '178 cm' },
//   { value: '180', label: '180 cm' },
//   { value: '183', label: '183 cm' },
//   { value: '185', label: '185 cm' },
//   { value: '188', label: '188 cm' },
//   { value: '191', label: '191 cm' },
//   { value: '193', label: '193 cm' },
//   { value: '196', label: '196 cm' },
//   { value: '198', label: '198 cm' },
//   { value: '201', label: '201 cm' },
//   { value: '203', label: '203 cm' },
//   { value: '206', label: '206 cm' },
//   { value: '208', label: '208 cm' },
//   { value: '211', label: '211 cm' },
//   { value: '213', label: '213 cm' },
// ]

// Create a const of keys from the User type
// const measurements = Object.keys(defaultPersonalDetails())
// const measurements = Object.keys(defaultPersonalDetails()).filter(
//   (key) => !['openToAgencies', 'openToTestShoots', 'agencyName', 'professionalLevel'].includes(key)
// )