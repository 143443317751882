import 'yet-another-react-lightbox/styles.css';
import { HeartIcon as HeartOutline } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolid } from '@heroicons/react/24/solid';
import { Badge, Flex, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useContext } from 'react';
import { captionBadge, editCaptionModalTitle, imageContainer, responsiveImage } from '~/app/(accounts)/accounts/styles.css';
import { IdentityContext, PeerAccountContext, SafeImage, useAppAuth } from '~/app/_components';
import { CaptionModalInner } from './CaptionModalInner';
import { useLikeHandler } from './useLikeImageHandler';
import { FolioItem } from '~/generated/graphql';
interface SortedFolioImageProps {
  fi: FolioItem;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface EditProps {
  fi: FolioItem;
  handleBadgeOnClick: (item: FolioItem) => void;
}

// function SolidHeart(handleUnLikeOnClick: (folioId: string) => Promise<void>, id: string) {
//   return (
//     <HeartSolid
//       // color="#f5a39d"
//       color="black"
//       height={25}
//       style={{
//         cursor: 'pointer',
//       }}
//       onClick={() => handleUnLikeOnClick(id)}
//     />
//   )
// }
//

function EditButton({
  fi,
  handleBadgeOnClick
}: EditProps) {
  return <Flex justify="center" data-sentry-element="Flex" data-sentry-component="EditButton" data-sentry-source-file="SortedFolioImage.tsx">
      <Badge color="black" size="md" style={{
      cursor: 'pointer'
    }} onClick={() => handleBadgeOnClick(fi)} classNames={{
      root: captionBadge
    }} data-sentry-element="Badge" data-sentry-source-file="SortedFolioImage.tsx">
        {fi.caption ? 'EDIT CAPTION' : 'ADD CAPTION'}
      </Badge>
    </Flex>;
}
function ActionsOwner({
  fi,
  handleBadgeOnClick
}: {
  fi: FolioItem;
  handleBadgeOnClick: (fi: FolioItem) => void;
}) {
  return <>
      {/* <Flex>
        <HeartSolid height={20} color="black" /> */}
      {/* <Text c="darkgray" size="sm" ml="5px">
          {fi.totalCountLikes}
        </Text> */}
      {/* </Flex> */}

      <Caption fi={fi} data-sentry-element="Caption" data-sentry-source-file="SortedFolioImage.tsx" />
      <EditButton fi={fi} handleBadgeOnClick={handleBadgeOnClick} data-sentry-element="EditButton" data-sentry-source-file="SortedFolioImage.tsx" />
    </>;
}
function Caption({
  fi
}: {
  fi: FolioItem;
}) {
  if (fi.caption) {
    return <Text size="md" c="dimmed">
        {fi.caption}
      </Text>;
  }
  return null;
}
function ActionsViewer({
  fi
}: {
  fi: FolioItem;
}) {
  const {
    isSignedIn
  } = useAppAuth();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const hasFolioItems = currentAccount?.folioItems && currentAccount.folioItems.length > 0;
  const {
    solidHeart,
    handleLikePhotoOnClick,
    handleUnLikeOnClick
  } = useLikeHandler({
    hasLiked: fi.hasLiked
  });
  if (!isSignedIn || !hasFolioItems) return <Caption fi={fi} />;
  if (solidHeart) return <>
        <Tooltip label="Remove like">
          <HeartSolid
      // color="#f5a39d"
      color="black" height={25} style={{
        cursor: 'pointer'
      }} onClick={() => handleUnLikeOnClick(fi.id)} />
        </Tooltip>
        <Caption fi={fi} />
      </>;
  return <>
      <Tooltip label="Like image" data-sentry-element="Tooltip" data-sentry-source-file="SortedFolioImage.tsx">
        <HeartOutline color="black" height={25} style={{
        cursor: 'pointer'
      }} onClick={() => handleLikePhotoOnClick(fi.id)} data-sentry-element="HeartOutline" data-sentry-source-file="SortedFolioImage.tsx" />
      </Tooltip>
      <Caption fi={fi} data-sentry-element="Caption" data-sentry-source-file="SortedFolioImage.tsx" />
    </>;
}
export function SortedFolioImage({
  fi,
  index,
  setIndex,
  setOpen
}: SortedFolioImageProps) {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const isOwner = currentAccount?.id === account.id;
  const [selectedImage, setSelectedImage] = React.useState<FolioItem | undefined>(undefined);
  const [opened, {
    open: captionOpen,
    close: captionClose
  }] = useDisclosure(false);
  function handleBadgeOnClick(item: FolioItem) {
    setSelectedImage(item);
    captionOpen();
  }
  return <>
      <Stack style={{
      position: 'relative'
    }} justify="center" gap={'5px'} className={imageContainer} data-sentry-element="Stack" data-sentry-source-file="SortedFolioImage.tsx">
        <Stack style={{
        position: 'relative'
      }} justify="center" gap="0" data-sentry-element="Stack" data-sentry-source-file="SortedFolioImage.tsx">
          <SafeImage src={fi.file.url} alt={`Image ${index + 1}`} priority={fi.order === 0} width={500} // Set a default width
        height={750} // Set a default height, aspect ratio will be maintained by CSS
        className={responsiveImage} onClick={() => {
          setIndex(index);
          setOpen(true);
        }} breakpoints={{
          xs: 100,
          sm: 70,
          md: 50,
          lg: 30,
          xl: 20
        }} data-sentry-element="SafeImage" data-sentry-source-file="SortedFolioImage.tsx" />
          {/* {isOwner ? <EditButton fi={fi} handleBadgeOnClick={handleBadgeOnClick} /> : null} */}
        </Stack>
        <Flex w="100%" justify="center" align="center" gap="xl" direction="row" data-sentry-element="Flex" data-sentry-source-file="SortedFolioImage.tsx">
          {isOwner ? <ActionsOwner fi={fi} handleBadgeOnClick={handleBadgeOnClick} /> : <ActionsViewer fi={fi} />}
        </Flex>
      </Stack>

      <Modal opened={opened} onClose={() => {
      setSelectedImage(undefined);
      captionClose();
    }} withCloseButton={false} title="GIVE YOUR IMAGE A CAPTION" classNames={{
      title: editCaptionModalTitle
    }} data-sentry-element="Modal" data-sentry-source-file="SortedFolioImage.tsx">
        <CaptionModalInner selectedImage={selectedImage} setSelected={setSelectedImage} closeCaption={captionClose} data-sentry-element="CaptionModalInner" data-sentry-source-file="SortedFolioImage.tsx" />
      </Modal>
    </>;
}