'use client';

import type React from 'react';
import { AppShell, Text, Card, Flex } from '@mantine/core';
import { useHeadroom } from '@mantine/hooks';
import { usePathname, useRouter } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';
import { AppLayoutContext, IdentityContext } from '../../context';
import { Header } from '../header';
import { StyledButton } from '../../buttons';
import Link from 'next/link';
export const defaultHeaderHeight = 70;
export const defaultHeaderHeightPx = `${defaultHeaderHeight}px`;
export function AppShellTemplate({
  children
}: React.PropsWithChildren) {
  const {
    hiddenHeader,
    mobile
  } = useContext(AppLayoutContext);
  const pinned = useHeadroom({
    fixedAt: 140
  });
  const [headerHeight, setHeaderHeight] = useState(defaultHeaderHeight);
  const pathname = usePathname();
  const router = useRouter();
  const {
    currentAccount
  } = useContext(IdentityContext);
  useEffect(() => {
    const headerHeightValue = hiddenHeader ? 0 : defaultHeaderHeight;
    document.body.style.setProperty('--mantine-header-height', `${headerHeightValue}px`);
    setHeaderHeight(headerHeightValue);
  }, [hiddenHeader]);
  const calculatePaddingTop = () => {
    if (pathname.includes('/onboarding')) return '0px';
    return mobile ? '60px' : '100px';
  };
  return <AppShell padding="0px" header={{
    height: headerHeight,
    collapsed: hiddenHeader || !pinned,
    offset: false
  }} data-sentry-element="AppShell" data-sentry-component="AppShellTemplate" data-sentry-source-file="AppShellTemplate.tsx">
      <AppShell.Header maw="100vw" withBorder={false} style={{
      backgroundColor: 'white',
      opacity: '0.9'
    }} data-sentry-element="unknown" data-sentry-source-file="AppShellTemplate.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="AppShellTemplate.tsx" />
      </AppShell.Header>
      <AppShell.Main pt={calculatePaddingTop()} data-sentry-element="unknown" data-sentry-source-file="AppShellTemplate.tsx">{children}</AppShell.Main>
      {currentAccount?.folioItems?.length ? null : <AppShell.Footer maw={mobile ? '95%' : '80%'} withBorder={false} style={{
      margin: '20px auto',
      borderRadius: ' 18px'
    }}>
          <Card p={mobile ? '10px' : undefined} style={{
        backgroundColor: 'f1f1f1',
        width: '100%',
        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.10), 0px 4px 6px rgba(0, 0, 0, 0.10)',
        borderRadius: '18px'
      }}>
            <Flex direction={mobile ? 'row' : 'row'} gap="xs" justify="space-between" align="center">
              <Text size={mobile ? '10px' : 'md'} w="70%">
                {mobile ? "You're missing a folio. Add one so you can" : "You're currently missing a folio. Add one so you can"}{' '}
                <Link href="/" passHref style={{
              textDecoration: 'none !important'
            }}>
                  <Text span c="blue">
                    connect with users
                  </Text>
                </Link>{' '}
                and{' '}
                <Link href="/projects" passHref style={{
              textDecoration: 'none !important'
            }}>
                  <Text span c="blue">
                    collaborate for shoots
                  </Text>
                </Link>
                .
              </Text>

              <StyledButton width="20%" disablePadding={mobile} extraSmallPadding={!mobile} noPadding={mobile} slimHeight text="ADD FOLIO" type="primary" onClick={() => router.push('/profile/')} />
            </Flex>
          </Card>
        </AppShell.Footer>}
    </AppShell>;
}