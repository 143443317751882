'use client';

import { Flex, Tabs, Title, Text } from '@mantine/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { IdentityContext, PeerAccountContext, SafeAvatarImage, SafeImage, StyledButton } from '~/app/_components';
import { meRoute } from '~/utils';
import { useRouter } from 'next/navigation';
import { ConnectionState } from '~/generated/graphql';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { ConnectButton } from '~/app/(accounts)/_components';
import About from '~/app/(accounts)/accounts/agency/About';
import BusinessFolioDesktop from '~/app/(accounts)/accounts/agency/BusinessFolioDesktop';
import { desktopAccountTabLabelActive, desktopAccountTabLabelUnactive } from '~/app/(accounts)/accounts/styles.css';
import { ProfileContactInfo } from './ProfileContactInfo';
const tabDataAgency = [{
  value: 'folio',
  label: 'FOLIO'
}, {
  value: 'about',
  label: 'ABOUT'
}
// { value: 'contact', label: 'CONTACT' },
];
export default function NewProfileDesktop() {
  const [activeTab, setActiveTab] = useState<string | null>('folio');
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const router = useRouter();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [align, setAlign] = useState<'center' | 'flex-start'>('center');
  useEffect(() => {
    if (titleRef.current) {
      const lineHeight = 0.79 * parseFloat(getComputedStyle(titleRef.current).fontSize || '16px');
      const rows = Math.round(titleRef.current.clientHeight / lineHeight);
      setAlign(rows >= 2 ? 'flex-start' : 'center');
    }
  }, [account?.profile?.name]);
  const isOwner = currentAccount?.id === account?.id;

  // In case someone follows a link to a profile with no folio items
  if (!isOwner && account?.folioItems?.length === 0) {
    router.push('/');
  }
  return <Flex m="xl" direction="column" p="xl" gap="md" mt="30px" data-sentry-element="Flex" data-sentry-component="NewProfileDesktop" data-sentry-source-file="NewProfileDesktop.tsx">
      <Flex direction="row" w="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="NewProfileDesktop.tsx">
        <Flex align={align} gap="md" mb="xl" data-sentry-element="Flex" data-sentry-source-file="NewProfileDesktop.tsx">
          <SafeAvatarImage radius="xl" account={account!} data-sentry-element="SafeAvatarImage" data-sentry-source-file="NewProfileDesktop.tsx" />
          <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="NewProfileDesktop.tsx">
            <Title order={1} size="100px" tt="uppercase" lh="79%" lts="-5px" ref={titleRef} data-testid="profile-title" data-sentry-element="Title" data-sentry-source-file="NewProfileDesktop.tsx">
              {account?.profile?.name}
            </Title>

            <Flex justify="flex-start" mt="15px" data-sentry-element="Flex" data-sentry-source-file="NewProfileDesktop.tsx">
              <Title order={3} tt="uppercase" data-sentry-element="Title" data-sentry-source-file="NewProfileDesktop.tsx">
                {account?.professions?.map(p => p.name).join(', ')}
              </Title>
              <Title order={3} tt="uppercase" mx="10px" data-sentry-element="Title" data-sentry-source-file="NewProfileDesktop.tsx">
                |
              </Title>
              <Title order={3} mb="xs" ta="right" tt="uppercase" data-sentry-element="Title" data-sentry-source-file="NewProfileDesktop.tsx">
                {account?.profile?.location}
              </Title>
              {currentAccount ? <Flex gap="sm" ml="50px">
                  {account.socialInfo?.website ? <a href={account.socialInfo?.website} target="_blank" rel="noreferrer noopener" style={{
                textDecoration: 'none',
                color: 'black'
              }}>
                      <GlobeAltIcon height={22} />
                    </a> : null}
                  {account.socialInfo?.igHandle ? <a href={`https://instagram.com/${account.socialInfo?.igHandle}`} target="_blank" rel="noreferrer noopener" style={{
                textDecoration: 'none'
              }}>
                      <SafeImage alt="avatar" height={20} width={20}
                // sizes={`${size}px`}
                style={{
                  objectFit: 'cover'
                }} src={'/icons/Instagram2.webp'} />
                    </a> : null}
                </Flex> : null}
            </Flex>
          </Flex>
        </Flex>

        {ActionButton()}
      </Flex>

      <Tabs defaultValue="folio" variant="pills" onChange={setActiveTab}
    // placement='left'
    w="100%"
    // radius="0px"
    data-sentry-element="Tabs" data-sentry-source-file="NewProfileDesktop.tsx">
        <Tabs.List grow style={{
        border: '1px solid black',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
      }} data-sentry-element="unknown" data-sentry-source-file="NewProfileDesktop.tsx">
          {tabDataAgency.map(tab => <Tabs.Tab key={tab.value} value={tab.value} color={activeTab === tab.value ? '#000' : 'transparent'} classNames={{
          tabLabel: activeTab === tab.value ? desktopAccountTabLabelActive : desktopAccountTabLabelUnactive
        }} fw={600} lh="16.5px"
        // style={{ paddingRight: '125px', paddingLeft: '125px' }}
        >
              {tab.label}
            </Tabs.Tab>)}
        </Tabs.List>
        <Flex justify="flex-start" style={{
        pointerEvents: 'auto'
      }} data-sentry-element="Flex" data-sentry-source-file="NewProfileDesktop.tsx">
          <Tabs.Panel value="folio" w="100%" data-sentry-element="unknown" data-sentry-source-file="NewProfileDesktop.tsx">
            {account.folioItems && account.folioItems?.length >= 1 ? <BusinessFolioDesktop /> : <EmptyProfileForOwner />}
          </Tabs.Panel>

          <Tabs.Panel value="about" w="100%" data-sentry-element="unknown" data-sentry-source-file="NewProfileDesktop.tsx">
            <About data-sentry-element="About" data-sentry-source-file="NewProfileDesktop.tsx" />
          </Tabs.Panel>
        </Flex>
      </Tabs>
    </Flex>;
  function ActionButton() {
    // const { currentAccount } = useContext(IdentityContext)

    return <Flex style={{
      zIndex: 10,
      position: 'relative',
      pointerEvents: 'auto'
    }} data-sentry-element="Flex" data-sentry-component="ActionButton" data-sentry-source-file="NewProfileDesktop.tsx">
        {account?.connectionStatus === ConnectionState.Connected ? <Flex direction="column" gap="3px">
            {/* <Badge color="black" size="md" mb="sm">
              Connected
             </Badge> */}
            <ProfileContactInfo />
          </Flex> : isOwner ? <Flex direction="column" gap="3px">
            <StyledButton type="passive" text="UPDATE PROFILE" disablePadding highHeight onClick={() => {
          router.push(meRoute().editProfile);
        }} />
          </Flex> : <Flex direction="column" gap="3px">
            <ConnectButton business flexDirection="row" />
          </Flex>}
      </Flex>;
  }
}
function EmptyProfileForOwner() {
  // const { account } = useContext(PeerAccountContext)
  // const router = useRouter()

  // this can theoretically happen when clicking a link
  // to a no folio profile
  // if (!account.folioItems?.length) router.push('/')

  return <Flex direction="column" gap="30px" h="300px" w="100%" style={{
    border: '1px dotted black'
  }} justify="center" align="center" mt="50px" px="40px" data-sentry-element="Flex" data-sentry-component="EmptyProfileForOwner" data-sentry-source-file="NewProfileDesktop.tsx">
      <Text size="lg" ta="center" px="37%" data-sentry-element="Text" data-sentry-source-file="NewProfileDesktop.tsx">
        Your profile is currently not visible to other users because it’s missing folio images.
      </Text>
      <StyledButton text="ADD FOLIO IMAGES" type="passive" disablePadding href={meRoute().editProfile} highHeight veryHighHeight data-sentry-element="StyledButton" data-sentry-source-file="NewProfileDesktop.tsx" />
    </Flex>;
}