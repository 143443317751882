import { Stack } from '@mantine/core';
import { AddFolio, AddProfileImage } from './common';
import { outerWrapper } from './styles.css';
import { useContext } from 'react';
import { AppLayoutContext } from '~/app/_components';
export function AddPhotos() {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <Stack gap="0px" pb="md" px={mobile ? '15px' : '15px'} className={outerWrapper} data-sentry-element="Stack" data-sentry-component="AddPhotos" data-sentry-source-file="AddPhotos.tsx">
      <AddProfileImage data-sentry-element="AddProfileImage" data-sentry-source-file="AddPhotos.tsx" />
      <AddFolio data-sentry-element="AddFolio" data-sentry-source-file="AddPhotos.tsx" />
    </Stack>;
}