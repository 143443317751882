'use client';

import { useQuery } from '@urql/next';
import { shallowEqual, useSelector } from '@xstate/react';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { graphql } from '~/generated';
import type { TrimmedCurrentAccountFragment, TrimmedCurrentUserFragment } from '~/generated/graphql';
import { appTrace } from '~/utils';
import { AuthActorContext, useAppAuth } from '../auth';
export type IdentityContextType = {
  currentAccount?: TrimmedCurrentAccountFragment;
  currentUser?: TrimmedCurrentUserFragment;
};
export const IdentityContext = createContext<IdentityContextType>({} as IdentityContextType);
const GetCurrentIdentityContextUser = graphql(`
  query IdentityContextUser {
    __typename
    currentUser {
      __typename
      ... on User {
        ...TrimmedCurrentUser
      }
      ... on Error {
        message
      }
    }
  }
`);
const GetCurrentIdentityContextAccount = graphql(`
  query IdentityContextAccount {
    __typename
    currentAccount {
      __typename
      ... on Account {
        ...TrimmedCurrentAccount
      }
      ... on Error {
        message
      }
    }
  }
`);
export const GetDetailedCurrentAccount = graphql(`
  query GetDetailedCurrentAccount {
    __typename
    currentAccount {
      __typename
      ... on Account {
        ...DetailedCurrentAccount
      }
      ... on Error {
        message
      }
    }
  }
`);
const isSSR = typeof window === 'undefined';
export function IdentityProvider({
  children
}: PropsWithChildren) {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  // NOTE:
  // We do not care about the stable auth state because
  // the only purpose of this provider is to keep the authActor up-to-date with graphcache
  // This query is only needed to keep the state machine context up to date
  // (when other mutations change currentAccount or currentUser)
  // The actual up-to-date state is fetched from network via mutations
  // - GenerateToken
  // - CreateCurrentUser
  // - updateCurrentAccount

  const [{
    data: userData
  }] = useQuery({
    query: GetCurrentIdentityContextUser,
    pause: isSSR,
    requestPolicy: 'cache-only'
  });
  const [{
    data: accountData
  }] = useQuery({
    query: GetCurrentIdentityContextAccount,
    pause: isSSR,
    requestPolicy: 'cache-only'
  });
  useEffect(() => {
    if (accountData?.currentAccount?.__typename === 'Account') {
      appTrace('IP: context update account', accountData);
      authActor.send({
        type: 'CURRENT_ACCOUNT',
        data: {
          account: accountData.currentAccount
        }
      });
    }
  }, [accountData]);
  useEffect(() => {
    if (userData?.currentUser?.__typename === 'User') {
      appTrace('IP: context update user', userData);
      authActor.send({
        type: 'CURRENT_USER',
        data: {
          user: userData.currentUser
        }
      });
    }
  }, [userData]);
  const values = useSelector(authActor, state => {
    return {
      currentUser: state.context.user || undefined,
      currentAccount: state.context.account || undefined
    };
  }, (a, b) => shallowEqual(a.currentUser, b.currentUser) && shallowEqual(a.currentAccount, b.currentAccount));
  return <IdentityContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="IdentityProvider" data-sentry-source-file="IdentityContext.tsx">{children}</IdentityContext.Provider>;
}
export function OnlySignedIn({
  children
}: PropsWithChildren) {
  const {
    isSignedIn
  } = useAppAuth();
  return isSignedIn ? <>{children}</> : null;
}