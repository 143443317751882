'use client';

import type { UserIcon } from '@heroicons/react/24/outline';
import { Button, useMantineTheme } from '@mantine/core';
import type React from 'react';
import { buttonPadding } from './styles.css';

// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
type MantineIconType = (props: React.ComponentProps<'svg'>) => JSX.Element;
type HeroIconType = typeof UserIcon;
export type StyledButtonProps = {
  type: 'primary' | 'secondary' | 'project' | 'gray' | 'passive' | 'black';
  text: string;
  textColor?: string;
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onClick?: (e?: React.MouseEvent<HTMLElement> | any) => void;
  href?: string;
  icon?: MantineIconType | HeroIconType;
  shape?: 'pill' | 'joinedAtCornerLeft' | 'joinedAtCornerRight' | 'square';
  isDisabled?: boolean;
  testId?: string;
  width?: string;
  disablePadding?: boolean;
  noPadding?: boolean;
  extraSmallPadding?: boolean;
  slimHeight?: boolean;
  highHeight?: boolean;
  veryHighHeight?: boolean;
  fz?: string;
  fw?: number;
  borderRadius?: string;
  isHidden?: boolean;
  // disabled?: boolean
};
export function StyledButton({
  type = 'primary',
  size = 'small',
  text,
  textColor,
  onClick,
  href,
  icon,
  shape = 'pill',
  isDisabled = false,
  isHidden = false,
  disablePadding = false,
  noPadding = false,
  extraSmallPadding = false,
  testId,
  width,
  slimHeight,
  highHeight,
  veryHighHeight,
  fz,
  fw,
  borderRadius
}:
// disabled = false,
StyledButtonProps) {
  const Icon = icon;
  const theme = useMantineTheme();

  // Made some color changes, wont spend more time on it until colors are set

  const colors = {
    primary: 'brand',
    // secondary: 'gray',
    gray: theme.colors.gray[0],
    secondary: 'black',
    project: 'project',
    passive: 'black',
    black: 'black'
  };
  const sizes = {
    xSmall: 'xs',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    xLarge: 'xl'
  };
  const variants = {
    gray: 'filled',
    primary: 'filled',
    secondary: 'filled',
    project: 'filled',
    passive: 'outline',
    black: 'filled'
  };
  const buttonProps = {
    onClick: () => {
      if (isDisabled) {
        console.error(`disabled button ${text} clicked`);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClick?.();
      }
    },
    href,
    // size: sizes[size],
    radius: borderRadius ? borderRadius : undefined,
    color: colors[type],
    variant: variants[type],
    disabled: isDisabled
  };
  const iconSize = size === 'small' ? 15 : 20;
  return <Button px={disablePadding ? noPadding ? '1px' : 'lg' : extraSmallPadding ? '5px' : '50px'} component="a" {...buttonProps} data-testid={testId} disabled={isDisabled} radius={'8px'} fz={fz ? fz : 'h5'} fw={fw ? fw : 600} style={{
    height: slimHeight ? '25px' : highHeight ? veryHighHeight ? '42px' : '34px' : 'var(--button-height-xs',
    width: width,
    color: textColor,
    display: isHidden ? 'none' : undefined
    // borderBottomLeftRadius: shape !== 'pill' && shape === 'joinedAtCornerLeft' ? '0px' : '8px',
    // borderTopLeftRadius: shape !== 'pill' && shape === 'joinedAtCornerLeft' ? '0px' : '8px',
    // borderBottomRightRadius: shape !== 'pill' && shape === 'joinedAtCornerLeft' ? '8px' : '8px',
    // borderTopRightRadius: shape !== 'pill' && shape === 'joinedAtCornerLeft' ? '8px' : '8px',
  }} classNames={{
    root: buttonPadding
  }} data-sentry-element="Button" data-sentry-component="StyledButton" data-sentry-source-file="Buttons.tsx">
      {Icon ? <Icon height={iconSize} width={iconSize} color="white" /> : null}
      {Icon ? <span>&nbsp;</span> : null}
      {text}
    </Button>;
}