import { HeartIcon as HeartOutline } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolid } from '@heroicons/react/24/solid';
import { Flex, Text } from '@mantine/core';
import { usePathname } from 'next/navigation';
import type { FolioItem } from '~/generated/graphql';
import { useLikeHandler } from '../desktop/useLikeImageHandler';
export function MobileLikeImage({
  image,
  id
}: {
  image: FolioItem;
  id: string;
}) {
  const pathname = usePathname();
  const {
    solidHeart,
    handleLikePhotoOnClick,
    handleUnLikeOnClick
  } = useLikeHandler({
    hasLiked: pathname.includes('/accounts') && image.hasLiked
  });
  return <Flex gap="xs" w="100%" align="center" mt="2px" data-sentry-element="Flex" data-sentry-component="MobileLikeImage" data-sentry-source-file="MobileLikeImage.tsx">
      {solidHeart ? <HeartSolid height={20} color="black" onClick={() => handleUnLikeOnClick(id)} /> : <HeartOutline height={20} color="black" onClick={() => handleLikePhotoOnClick(id)} />}
      <Text size="10px" maw="80%" style={{
      zIndex: '1000'
    }} data-sentry-element="Text" data-sentry-source-file="MobileLikeImage.tsx">
        {image.caption || ''}
      </Text>
    </Flex>;
}