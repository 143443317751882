'use client';

import { Badge, Card, Flex, ScrollArea, Text } from '@mantine/core';
import { ConnectButton } from '~/app/(accounts)/_components';
import { AccountAvatarBlockDektop } from '~/app/(accounts)/_components/desktop/AccountAvatarBlockDesktop';
import { IdentityContext, PeerAccountContext, StyledButton } from '~/app/_components';
import { ConnectionState } from '~/generated/graphql';
import { meRoute } from '~/utils';
import { ProfilePhotoAlbum } from './ProfilePhotoAlbum';
import { useContext } from 'react';
import { ProfileContactInfo } from './ProfileContactInfo';
function ActionButton() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  if (isCurrentUserProfile) return <StyledButton type="black" text="EDIT PROFILE" href={meRoute().editProfile} width="100%" />;
  if (account.connectionStatus === ConnectionState.Connected) return <ProfileContactInfo />;
  return <ConnectButton flexDirection="row" data-sentry-element="ConnectButton" data-sentry-component="ActionButton" data-sentry-source-file="ProfileDesktop.tsx" />;
}
function ProfileWithFolio() {
  const {
    account
  } = useContext(PeerAccountContext);
  if (!account) return null;
  return <Flex px="70px" style={{
    minHeight: 'calc(100vh - 70px)'
  }} gap="100px" data-sentry-element="Flex" data-sentry-component="ProfileWithFolio" data-sentry-source-file="ProfileDesktop.tsx">
      <Flex w="30%" h={'calc(100vh - 100px)'} mah={'calc(100vh - 100px)'} data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
        <Flex direction="column" align="center"
      // justify="center"
      mt="100px" gap="lg" h="100%" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
          {/* <ScrollArea type="hover" offsetScrollbars={true} scrollbarSize="sm" mah="70%"> */}
          <Flex direction="column" align="center" justify="center" gap="30px"
        // h="50%"
        data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
            <Flex direction="column" gap="xs" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
              {account.connectionStatus === ConnectionState.Connected ? <Badge size="xs" color="gray.2">
                  CONNECTED
                </Badge> : null}
              <AccountAvatarBlockDektop data-sentry-element="AccountAvatarBlockDektop" data-sentry-source-file="ProfileDesktop.tsx" />
            </Flex>

            <ActionButton data-sentry-element="ActionButton" data-sentry-source-file="ProfileDesktop.tsx" />

            <Text size="lg" data-testid="about-desktop-section" data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">
              {account.profile?.about}
            </Text>
          </Flex>
          {/* </ScrollArea> */}
        </Flex>
      </Flex>

      <Flex w="70%" justify="center" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
        <Flex mt="100px" justify="center" h="100%" w="100%" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
          {account.folioItems && account.folioItems?.length >= 1 ? <div style={{
          minHeight: '200px',
          marginBottom: '10vh'
          // marginTop: '50px',
        }}>
              <ProfilePhotoAlbum />
            </div> : <EmptyProfileForOwner />}
        </Flex>
      </Flex>
    </Flex>;
}
function EmptyProfileForOwner() {
  const {
    account
  } = useContext(PeerAccountContext);
  return <Flex direction="column" gap="md" h="60%" w="60%" style={{
    border: '1px dotted black'
  }} justify="center" align="center" px="70px" data-sentry-element="Flex" data-sentry-component="EmptyProfileForOwner" data-sentry-source-file="ProfileDesktop.tsx">
      <Text size="md" data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">Your profile is currently not visible to other users because it’s missing folio images.</Text>
      <StyledButton text="ADD FOLIO IMAGES" type="passive" disablePadding href={meRoute().editProfile} data-sentry-element="StyledButton" data-sentry-source-file="ProfileDesktop.tsx" />
    </Flex>;
}

// NOT USED ANYMORE, DELETE WHEN SAFE
export function ProfileDesktop() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;

  // NOTE: showing profile even if no folios, but is not visible in community, search, or contacts
  const hasFolioItems = account.folioItems && account.folioItems.length > 0;

  // if (isCurrentUserProfile && !hasFolioItems) return <EmptyProfileForOwner />
  return <ProfileWithFolio data-sentry-element="ProfileWithFolio" data-sentry-component="ProfileDesktop" data-sentry-source-file="ProfileDesktop.tsx" />;
}