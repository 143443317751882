import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CloseButton, Grid } from '@mantine/core';
import { useMutation } from '@urql/next';
import { useContext } from 'react';
import { updateCurrentAccount } from '~/app/(me)/_lib/updateCurrentAccount';
import { AppLayoutContext, SafeImage } from '~/app/_components';
import { folioGridColumn, folioGridEditDeleteIcon, folioGridEditDeleteIconMobile } from './styles.css';
export type PhotoType = {
  src: string;
  id: string;
};
export function SortablePhoto({
  photo,
  id
}: {
  photo: string;
  id: string;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [, executeUpdateAccount] = useMutation(updateCurrentAccount);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: id
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  function handleDeletePhoto() {
    executeUpdateAccount({
      account: {
        folioItems: {
          delete: [{
            id: id
          }]
        }
      }
    });
  }
  return <Grid.Col style={style} {...attributes} span={mobile ? 3 : 'content'} key={id} className={folioGridColumn} ref={setNodeRef} data-sentry-element="unknown" data-sentry-component="SortablePhoto" data-sentry-source-file="sortablePhoto.tsx">
      <div style={{
      width: mobile ? '100%' : undefined,
      height: mobile ? 'auto' : undefined
    }}>
        <SafeImage alt="Folio Image" src={photo} {...listeners} height={100} width={100} data-testid={`folio-img-${id}`} style={{
        objectFit: 'contain',
        width: mobile ? '100%' : undefined,
        height: mobile ? '100%' : undefined
      }} data-sentry-element="SafeImage" data-sentry-source-file="sortablePhoto.tsx" />
        <CloseButton size={mobile ? 'sm' : 'sm'} aria-label="Remove" onClick={handleDeletePhoto} className={mobile ? folioGridEditDeleteIconMobile : folioGridEditDeleteIcon} data-testid="delete-folio-btn" data-sentry-element="CloseButton" data-sentry-source-file="sortablePhoto.tsx" />
      </div>
    </Grid.Col>;
}