'use client';

import { useDisclosure } from '@mantine/hooks';
import { Flex } from '@mantine/core';
import { useContext, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonMultiselectField, CommonSelectField, CommonTextField, MeasurementsFields, ProfileFormValues } from '~/app/(me)/_components/common/form';
import { AppLayoutContext, RemoteConfigContext } from '~/app/_components';
import { labelDescription, textInputRoot } from './styles.css';
import { usePathname } from 'next/navigation';
import { ProfessionalLevel } from '~/generated/types';
export function ProfessionsSelect() {
  const {
    professions
  } = useContext(RemoteConfigContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const profileForm = useFormContext<ProfileFormValues>();
  // const { setValue } = profileForm
  const pn = usePathname();
  const [hasAgency, setHasAgency] = useState(false);

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const [_openedProfessionSelection, {
    open: openProfessionSelection,
    close: _closeProfessionSelection
  }] = useDisclosure(false);

  // Subscribe to the field's value changes
  const selectedProfessions = useWatch({
    control: profileForm.control,
    name: 'professions',
    defaultValue: []
  });
  const yesOrNoSelection = [{
    value: 'false',
    label: 'No'
  }, {
    value: 'true',
    label: 'Yes'
  }];

  // const professionBadges = professions.filter((prof) => selectedProfessions.includes(prof.id))

  // function handleProfessionRemoval(profId: string) {
  //   // setLocationSearch((prevLocationSearch) => {
  //   //   return prevLocationSearch.filter((loc: string) => loc !== location)
  //   // })
  //   setValue(
  //     'professions',
  //     selectedProfessions.filter((prof) => prof !== profId)
  //   )

  //   setProfessionsBadgeIds((prevProfessionBadges) => {
  //     return prevProfessionBadges.filter((prof: string) => prof !== profession)
  //   })
  // }

  return <Flex direction="column" gap={mobile ? '20px' : 'xl'} data-sentry-element="Flex" data-sentry-component="ProfessionsSelect" data-sentry-source-file="ProfessionsSelect.tsx">
      <CommonMultiselectField name="professions" label="PROFESSION" description="Choose your professions. You can select more than one." data={professions.map(p => ({
      value: p.id,
      label: p.name
    }))} placeholder={selectedProfessions.length ? '' : 'Select'} data-testid="professions-select" classNames={{
      description: labelDescription,
      input: textInputRoot
    }} data-sentry-element="CommonMultiselectField" data-sentry-source-file="ProfessionsSelect.tsx" />
      {/* <Flex>
        {professionBadges.map((prof, i) => (
          <Badge
            mt="sm"
            mr="sm"
            h={25}
            c="black"
            fz="13px"
            key={i}
            color="#f5f5f5"
            rightSection={
              <XMarkIcon height={15} onClick={(_e) => handleProfessionRemoval(prof.id)} style={{ cursor: 'pointer' }} />
            }
          >
            {prof.name}
          </Badge>
        ))}
       </Flex> */}
      {pn.includes('/onboarding/profile') ? null : <>
          <MeasurementsFields />

          <CommonSelectField name="field_is_only_for_ui" label="AGENCY REPRESENTED" description="Let us know if you’re signed or unsigned by a talent agency." size="md" placeholder="Select" data={yesOrNoSelection} onChange={e => setHasAgency(e === 'true')} clearable classNames={{
        description: labelDescription,
        input: textInputRoot
      }} />

          <CommonTextField disabled={!hasAgency} required size="md" label="AGENCY NAME" description="Who are you represented by." name="personalDetails.agencyName" classNames={{
        description: labelDescription,
        input: textInputRoot
      }} />

          <CommonSelectField name="personalDetails.openToAgencies" label="ARE YOU OPEN TO BE SIGNED BY AN AGENCY?" size="md" placeholder="Select" data={yesOrNoSelection} clearable />

          <CommonSelectField name="personalDetails.professionalLevel" label="PROFESSIONAL LEVEL" size="md" placeholder="Select" data={[{
        value: ProfessionalLevel.Student,
        label: 'Student'
      }, {
        value: ProfessionalLevel.Entry,
        label: 'Entry'
      }, {
        value: ProfessionalLevel.Middle,
        label: 'Middle'
      }, {
        value: ProfessionalLevel.Senior,
        label: 'Senior'
      }]} clearable />

          <CommonSelectField name="personalDetails.openToTestShoots" label="ARE YOU OPEN TO TEST SHOOTS?" size="md" placeholder="Select" data={yesOrNoSelection} clearable />
        </>}
    </Flex>;
}