import { offlineExchange, cacheExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import type { Exchange } from '@urql/next'

import schema from 'src/generated/introspection'
import type { GraphCacheConfig } from '~/generated/graphql'
import { optimsticUpdaters } from '~/graphql/resolvers/folio'

type Config = Parameters<typeof cacheExchange>[0]
const sharedConfig: GraphCacheConfig & Config = {
  schema,
  keys: {
    Profile: () => null,
    PersonalDetails: () => null,
    ContactInfo: () => null,
    SocialInfo: () => null,
    File: () => null,
    Error: () => null,
    UserSettings: () => null,
    AccountNotificationsConnection: () => null,
    AccountProjectsConnection: () => null,
    ProjectInterestsConnection: () => null,
    User: (args) => args.id!,
    Account: (args) => args.id!,
    Project: (args) => args.id!,
    Friendship: (args) => args.id!,
    Notification: (args) => args.id!,
  },
  updates: {},
  optimistic: optimsticUpdaters,
}

export function appCacheExchange(): Exchange {
  const storage = makeDefaultStorage({ idbName: 'cl-v1', maxAge: 7 })
  const config: GraphCacheConfig & Parameters<typeof offlineExchange>[0] = {
    ...sharedConfig,
    storage, //: storage as NonNullable<CacheExchangeOpts['storage']>,
  }

  return offlineExchange(config)
}

export function rscCacheExchange(): Exchange {
  return cacheExchange(sharedConfig)
}
