'use client';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { AccountUpdateableState } from '~/generated/graphql';
export function OnboardingStagesField(props: {
  nextStage: AccountUpdateableState;
  prevStage?: AccountUpdateableState;
}) {
  const profileForm = useFormContext();
  const nextStageField = 'state';
  const prevStageField = 'allowedPrevState';
  useEffect(() => {
    // for some reason form state persists across navigation
    profileForm.setValue(nextStageField, props.nextStage);
    if (props.prevStage) profileForm.setValue(prevStageField, props.prevStage);
  }, []);
  return <>
      <input type="hidden" readOnly={true} {...profileForm.register(nextStageField, {
      value: props.nextStage
    })} />
      {/* <input type="hidden" readOnly={true} {...profileForm.register(prevStageField, { value: props.prevStage })} /> */}
    </>;
}