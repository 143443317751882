'use client';

import '@mantine/carousel/styles.css';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { Carousel, CarouselProps, type Embla } from '@mantine/carousel';
import { Card, Flex, Text } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import type { FolioItem } from '~/generated/graphql';
import { appTrace } from '~/utils';
import { sortedFolioItems } from '../../(accounts)/_lib';
import { AppLayoutContext, PeerAccountContext } from '../context';
import { SafeImage } from '../images';
import { useInView } from 'react-intersection-observer';
function FittedCarouselImage({
  image,
  id,
  priority
}: {
  image: FolioItem;
  id: string;
  priority: boolean;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  // const { currentAccount } = useContext(IdentityContext)
  const pathname = usePathname();
  return <>
      <SafeImage alt="" fill priority={priority} quality={priority ? 75 : 50} style={{
      objectFit: 'contain',
      maxHeight: mobile && pathname.includes('/accounts') ? '90%' : undefined
    }} src={image.file.url} breakpoints={{
      xs: 100,
      sm: 70,
      md: 50,
      lg: 30,
      xl: 20
    }} data-sentry-element="SafeImage" data-sentry-source-file="default.tsx" />
    </>;
}
function ImagesCarouselWrapper({
  children
}: React.PropsWithChildren) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <Flex justify="center" style={{
    aspectRatio: 1,
    padding: mobile ? '0' : '0px 10px'
  }} data-sentry-element="Flex" data-sentry-component="ImagesCarouselWrapper" data-sentry-source-file="default.tsx">
      {children}
    </Flex>;
}
export function ImagesCarousel() {
  const {
    account
  } = useContext(PeerAccountContext);
  return account.folioItems && account.folioItems.length > 0 ? <ImagesCarouselWrapper data-sentry-element="ImagesCarouselWrapper" data-sentry-component="ImagesCarousel" data-sentry-source-file="default.tsx">
      <ImagesCarouselInner data-sentry-element="ImagesCarouselInner" data-sentry-source-file="default.tsx" />
    </ImagesCarouselWrapper> : <Flex w="100%" justify="center" data-sentry-element="Flex" data-sentry-component="ImagesCarousel" data-sentry-source-file="default.tsx">
      <Card bg="#48FFB9" w="80%" p="md" radius="lg" my="xl" data-sentry-element="Card" data-sentry-source-file="default.tsx">
        <Flex direction="column" gap="md" justify="center" data-sentry-element="Flex" data-sentry-source-file="default.tsx">
          <Text ta="center" size="lg" data-sentry-element="Text" data-sentry-source-file="default.tsx">
            Your profile is currently not visible to other users because it's missing folio images.
          </Text>
          <Text ta="center" size="lg" data-sentry-element="Text" data-sentry-source-file="default.tsx">
            Click <span style={{
            fontWeight: 'bold'
          }}>EDIT PROFILE</span> to add some.
          </Text>
        </Flex>
      </Card>
    </Flex>;
}
function ImagesCarouselInner() {
  const pn = usePathname();
  const {
    account
  } = useContext(PeerAccountContext);
  const autoloop = !pn.includes('/accounts');
  const [_embla, setEmbla] = useState<Embla | null>(null);
  const autoplay = useRef(Autoplay({
    delay: 500,
    playOnInit: false,
    stopOnInteraction: true
  }));

  // Needed to prevent embla from autoplaying when the user is not looking at the carousel
  // The bug happens when user switches the tabs back and forth when the carousel is loading
  const {
    ref
  } = useInView({
    triggerOnce: false,
    initialInView: false,
    onChange: () => {
      autoplay.current.stop();
    }
  });
  const images = sortedFolioItems(account).map(image => <FittedCarouselImage priority={image.order === 0} key={image.id} image={image} id={image.id} />);
  const slides = images.map(image => <Carousel.Slide key={image.key} style={{
    display: 'flex',
    flexDirection: 'column-reverse'
  }}>
      {image}
    </Carousel.Slide>);
  const carouselProps: CarouselProps = {
    withControls: false,
    withIndicators: true,
    height: '100%',
    w: '100%',
    loop: true,
    slideGap: 'md',
    includeGapInSize: false,
    nextControlIcon: <ArrowRightIcon height={15} />,
    previousControlIcon: <ArrowLeftIcon height={15} />,
    styles: {
      indicator: {
        backgroundColor: '#f1f1f1',
        width: '15px',
        height: '2px',
        position: 'relative',
        '&:dataActive': {
          backgroundColor: '#000'
        },
        bottom: autoloop ? undefined : '40px'
      }
    }
  };
  const allProps = useMemo(() => {
    if (autoloop) {
      return {
        ...carouselProps,
        getEmblaApi: setEmbla,
        plugins: [autoplay.current],
        onMouseEnter: () => {
          appTrace('Mouse entered, starting autoplay');
          autoplay.current.play();
        },
        onMouseLeave: () => {
          appTrace('Mouse left, stopping autoplay');
          autoplay.current.stop();
        }
      };
    }
    return carouselProps;
  }, []);
  return <Carousel ref={ref} {...allProps} style={{
    maxWidth: '100%'
  }} data-sentry-element="Carousel" data-sentry-component="ImagesCarouselInner" data-sentry-source-file="default.tsx">
      {slides}
    </Carousel>;
}