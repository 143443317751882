export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  agencyTalents?: Maybe<Array<AgencyTalent>>;
  authoredMessages?: Maybe<AccountAuthoredMessagesConnection>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  connectionStatus?: Maybe<ConnectionState>;
  contactInfo?: Maybe<ContactInfo>;
  createdAt: Scalars['DateTime']['output'];
  folders?: Maybe<Array<Folder>>;
  folioItems?: Maybe<Array<FolioItem>>;
  friends?: Maybe<AccountFriendsConnection>;
  id: Scalars['ID']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<Message>>;
  notifications?: Maybe<AccountNotificationsConnection>;
  personalDetails?: Maybe<PersonalDetails>;
  professions?: Maybe<Array<Profession>>;
  profile?: Maybe<Profile>;
  projects?: Maybe<AccountProjectsConnection>;
  receivedFriendships?: Maybe<AccountReceivedFriendshipsConnection>;
  receivedMessages?: Maybe<AccountReceivedMessagesConnection>;
  requestedFriendships?: Maybe<AccountRequestedFriendshipsConnection>;
  socialInfo?: Maybe<SocialInfo>;
  state?: Maybe<AccountState>;
  talentAgencies?: Maybe<Array<AgencyTalent>>;
  tier?: Maybe<AccountTier>;
  type?: Maybe<AccountType>;
  updatedAt: Scalars['DateTime']['output'];
  videoItems?: Maybe<Array<VideoItem>>;
};


export type AccountAuthoredMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<AccountsSearchQuery>;
};


export type AccountNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  readState?: InputMaybe<NotificationReadState>;
};


export type AccountProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ProjectsSearchQuery>;
};


export type AccountReceivedFriendshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountReceivedMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountRequestedFriendshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountAuthoredMessagesConnection = {
  __typename?: 'AccountAuthoredMessagesConnection';
  edges: Array<AccountAuthoredMessagesConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountAuthoredMessagesConnectionEdge = {
  __typename?: 'AccountAuthoredMessagesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type AccountCreateAvatarRelationInput = {
  create?: InputMaybe<AvatarCreateInput>;
};

export type AccountCreateContactInfoRelationInput = {
  create?: InputMaybe<ContactInfoCreateInput>;
};

export type AccountCreateInput = {
  contactInfo?: InputMaybe<AccountCreateContactInfoRelationInput>;
  personalDetails?: InputMaybe<AccountCreatePersonalDetailsRelationInput>;
  professions?: InputMaybe<AccountCreateProfessionsRelationInput>;
  profile?: InputMaybe<AccountCreateProfileRelationInput>;
  socialInfo?: InputMaybe<AccountCreateSocialInfoRelationInput>;
  state?: InputMaybe<AccountUpdateableState>;
  type?: InputMaybe<AccountType>;
};

export type AccountCreatePersonalDetailsRelationInput = {
  create?: InputMaybe<PersonalDetailsCreateInput>;
};

export type AccountCreateProfessionsRelationInput = {
  connect?: InputMaybe<Array<ProfessionUniqueFilter>>;
};

export type AccountCreateProfileRelationInput = {
  create?: InputMaybe<ProfileCreateInput>;
};

export type AccountCreateSocialInfoRelationInput = {
  create?: InputMaybe<SocialInfoCreateInput>;
};

export type AccountFilter = {
  AND?: InputMaybe<Array<AccountFilter>>;
  OR?: InputMaybe<Array<AccountFilter>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  professions?: InputMaybe<ProfessionListFilter>;
  profile?: InputMaybe<ProfileFilter>;
};

export type AccountFriendsConnection = {
  __typename?: 'AccountFriendsConnection';
  edges: Array<AccountFriendsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountFriendsConnectionEdge = {
  __typename?: 'AccountFriendsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type AccountNotificationsConnection = {
  __typename?: 'AccountNotificationsConnection';
  edges: Array<AccountNotificationsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountNotificationsConnectionEdge = {
  __typename?: 'AccountNotificationsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export type AccountOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  profile?: InputMaybe<ProfileOrderBy>;
};

export enum AccountPreset {
  Default = 'Default',
  NoFolio = 'NoFolio',
  None = 'None'
}

export type AccountProjectsConnection = {
  __typename?: 'AccountProjectsConnection';
  edges: Array<AccountProjectsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountProjectsConnectionEdge = {
  __typename?: 'AccountProjectsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type AccountReceivedFriendshipsConnection = {
  __typename?: 'AccountReceivedFriendshipsConnection';
  edges: Array<AccountReceivedFriendshipsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountReceivedFriendshipsConnectionEdge = {
  __typename?: 'AccountReceivedFriendshipsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Friendship;
};

export type AccountReceivedMessagesConnection = {
  __typename?: 'AccountReceivedMessagesConnection';
  edges: Array<AccountReceivedMessagesConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountReceivedMessagesConnectionEdge = {
  __typename?: 'AccountReceivedMessagesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type AccountRequestedFriendshipsConnection = {
  __typename?: 'AccountRequestedFriendshipsConnection';
  edges: Array<AccountRequestedFriendshipsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountRequestedFriendshipsConnectionEdge = {
  __typename?: 'AccountRequestedFriendshipsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Friendship;
};

export enum AccountState {
  Active = 'Active',
  OnboardingAddTalent = 'OnboardingAddTalent',
  OnboardingChooseAccountType = 'OnboardingChooseAccountType',
  OnboardingChoosePlan = 'OnboardingChoosePlan',
  OnboardingPhotos = 'OnboardingPhotos',
  OnboardingProfileBasic = 'OnboardingProfileBasic',
  OnboardingProfileExtra = 'OnboardingProfileExtra',
  Suspended = 'Suspended'
}

export enum AccountTier {
  Basic = 'BASIC',
  Pro = 'PRO'
}

export enum AccountType {
  Brand = 'Brand',
  Personal = 'Personal',
  TalentAgency = 'TalentAgency'
}

export type AccountUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AccountUpdateContactInfoRelationInput = {
  update?: InputMaybe<ContactInfoUpdateInput>;
};

export type AccountUpdateFoldersRelationInput = {
  create?: InputMaybe<Array<FolderCreateInput>>;
  delete?: InputMaybe<Array<FolderUniqueFilter>>;
};

export type AccountUpdateFolioItemsRelationInput = {
  create?: InputMaybe<Array<FolioItemCreateInput>>;
  delete?: InputMaybe<Array<FolioUniqueFilter>>;
  update?: InputMaybe<Array<AccountUpdateFolioItemsRelationInputUpdate>>;
};

export type AccountUpdateFolioItemsRelationInputUpdate = {
  data?: InputMaybe<FolioItemUpdateInput>;
  where?: InputMaybe<FolioUniqueFilter>;
};

export type AccountUpdateInput = {
  avatar?: InputMaybe<AccountCreateAvatarRelationInput>;
  contactInfo?: InputMaybe<AccountUpdateContactInfoRelationInput>;
  folders?: InputMaybe<AccountUpdateFoldersRelationInput>;
  folioItems?: InputMaybe<AccountUpdateFolioItemsRelationInput>;
  notifications?: InputMaybe<AccountUpdateNotificationsRelationInput>;
  personalDetails?: InputMaybe<AccountUpdatePersonalDetailsRelationInput>;
  professions?: InputMaybe<AccountUpdateProfessionsRelationInput>;
  profile?: InputMaybe<AccountUpdateProfileRelationInput>;
  socialInfo?: InputMaybe<AccountUpdateSocialInfoRelationInput>;
  state?: InputMaybe<AccountUpdateableState>;
  tier?: InputMaybe<AccountUpdateableTier>;
  videoItems?: InputMaybe<AccountUpdateVideoItemsRelationInput>;
};

export type AccountUpdateNotificationsRelationInput = {
  update?: InputMaybe<Array<AccountUpdateNotificationsRelationInputUpdate>>;
  updateMany?: InputMaybe<Array<AccountUpdateNotificationsRelationInputUpdateMany>>;
};

export type AccountUpdateNotificationsRelationInputUpdate = {
  data?: InputMaybe<NotificationUpdateInput>;
  where?: InputMaybe<NotificationUniqueFilter>;
};

export type AccountUpdateNotificationsRelationInputUpdateMany = {
  data?: InputMaybe<NotificationUpdateInput>;
  where?: InputMaybe<NotificationFilter>;
};

export type AccountUpdatePersonalDetailsRelationInput = {
  update?: InputMaybe<PersonalDetailsUpdateInput>;
};

export type AccountUpdateProfessionsRelationInput = {
  connect?: InputMaybe<Array<ProfessionUniqueFilter>>;
  disconnect?: InputMaybe<Array<ProfessionUniqueFilter>>;
  set?: InputMaybe<Array<ProfessionUniqueFilter>>;
};

export type AccountUpdateProfileRelationInput = {
  update?: InputMaybe<ProfileUpdateInput>;
};

export type AccountUpdateSocialInfoRelationInput = {
  update?: InputMaybe<SocialInfoUpdateInput>;
};

export type AccountUpdateVideoItemsRelationInput = {
  create?: InputMaybe<Array<VideoItemCreateInput>>;
  delete?: InputMaybe<Array<VideoUniqueFilter>>;
  update?: InputMaybe<Array<AccountUpdateVideoItemsRelationInputUpdate>>;
};

export type AccountUpdateVideoItemsRelationInputUpdate = {
  data?: InputMaybe<VideoItemUpdateInput>;
  where?: InputMaybe<VideoUniqueFilter>;
};

export enum AccountUpdateableState {
  Active = 'Active',
  OnboardingAddTalent = 'OnboardingAddTalent',
  OnboardingChooseAccountType = 'OnboardingChooseAccountType',
  OnboardingChoosePlan = 'OnboardingChoosePlan',
  OnboardingPhotos = 'OnboardingPhotos',
  OnboardingProfileBasic = 'OnboardingProfileBasic',
  OnboardingProfileExtra = 'OnboardingProfileExtra'
}

export enum AccountUpdateableTier {
  Basic = 'BASIC',
  Pro = 'PRO'
}

export type AccountsSearchQuery = {
  /** distance from current account in km */
  distance?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountOrderBy>;
  preset?: InputMaybe<AccountPreset>;
  where?: InputMaybe<AccountFilter>;
};

export type AgencyTalent = {
  __typename?: 'AgencyTalent';
  agency?: Maybe<Account>;
  agencyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  notifications?: Maybe<Array<Notification>>;
  state?: Maybe<Scalars['String']['output']>;
  talent?: Maybe<Account>;
  talentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AvatarCreateFileRelationInput = {
  create?: InputMaybe<FileCreateInput>;
};

export type AvatarCreateInput = {
  file: AvatarCreateFileRelationInput;
};

export enum ConnectionState {
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  Received = 'Received',
  Sent = 'Sent'
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoCreateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactInfoUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CurrentUserWithToken = {
  __typename?: 'CurrentUserWithToken';
  account: Account;
  token: Scalars['String']['output'];
  user: User;
};

export type DateTimeFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DressSizeType {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL'
}

export type Error = {
  __typename?: 'Error';
  message: Scalars['String']['output'];
};

export enum EyesType {
  Blue = 'Blue',
  Brown = 'Brown',
  Green = 'Green'
}

export type File = {
  __typename?: 'File';
  url: Scalars['String']['output'];
};

export type FileCreateInput = {
  key: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  service?: InputMaybe<FileService>;
  size: Scalars['Int']['input'];
};

export enum FileService {
  MinioMedia = 'MinioMedia'
}

export enum FileType {
  Avatar = 'Avatar',
  Folio = 'Folio',
  ProfilePhoto = 'ProfilePhoto',
  Unknown = 'Unknown'
}

export enum FileTypeLike {
  Avatar = 'Avatar',
  Folio = 'Folio',
  ProfilePhoto = 'ProfilePhoto',
  Unknown = 'Unknown'
}

export type Folder = {
  __typename?: 'Folder';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  folioItems?: Maybe<Array<FolioItem>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
  videoItems?: Maybe<Array<VideoItem>>;
};

export type FolderCreateInput = {
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type FolderUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FolioItem = {
  __typename?: 'FolioItem';
  account?: Maybe<Account>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file: File;
  hasLiked: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  totalCountLikes: Scalars['Int']['output'];
};

export type FolioItemCreateFileRelationInput = {
  create?: InputMaybe<FileCreateInput>;
};

/** If the order is skipped it will make it first + autosort/update related items */
export type FolioItemCreateInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  file: FolioItemCreateFileRelationInput;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type FolioItemUpdateInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type FolioUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Friendship = {
  __typename?: 'Friendship';
  addressee?: Maybe<Account>;
  id: Scalars['ID']['output'];
  messages?: Maybe<Array<Message>>;
  requester?: Maybe<Account>;
  state?: Maybe<FriendshipState>;
};

export enum FriendshipState {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export type FriendshipWithAccount = {
  __typename?: 'FriendshipWithAccount';
  account: Account;
  friendship: Friendship;
};

export enum Hairtype {
  Black = 'Black',
  Blue = 'Blue',
  Brown = 'Brown',
  Red = 'Red'
}

export type Like = {
  __typename?: 'Like';
  account: Account;
  accountId: Scalars['String']['output'];
  folioItem?: Maybe<FolioItem>;
  folioItemId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  videoItem?: Maybe<VideoItem>;
  videoItemId?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'Message';
  authorId: Scalars['ID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  friendships?: Maybe<Array<Friendship>>;
  id: Scalars['ID']['output'];
  recipientId: Scalars['ID']['output'];
};

export type MessageCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
};

export type MessageUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOrCreateFriendship?: Maybe<MutationAcceptOrCreateFriendshipResult>;
  authenticate?: Maybe<MutationAuthenticateResult>;
  createCurrentUser?: Maybe<MutationCreateCurrentUserResult>;
  createProject?: Maybe<MutationCreateProjectResult>;
  declineFriendship?: Maybe<MutationDeclineFriendshipResult>;
  deleteAccount?: Maybe<MutationDeleteAccountResult>;
  impersonate?: Maybe<MutationImpersonateResult>;
  likeImage?: Maybe<MutationLikeImageResult>;
  likeVideo?: Maybe<MutationLikeVideoResult>;
  makeException?: Maybe<MutationMakeExceptionResult>;
  markAsRead?: Maybe<MutationMarkAsReadResult>;
  nonShortlistApplicant?: Maybe<MutationNonShortlistApplicantResult>;
  sendMessage?: Maybe<MutationSendMessageResult>;
  sendNoFolioReminderEmail?: Maybe<MutationSendNoFolioReminderEmailResult>;
  sendNotification?: Maybe<MutationSendNotificationResult>;
  sendWelcomeEmailFromAdmin?: Maybe<MutationSendWelcomeEmailFromAdminResult>;
  shortlistApplicant?: Maybe<MutationShortlistApplicantResult>;
  showInterestInProject?: Maybe<MutationShowInterestInProjectResult>;
  updateCurrentAccount?: Maybe<MutationUpdateCurrentAccountResult>;
  updateCurrentUser?: Maybe<MutationUpdateCurrentUserResult>;
  updateProject?: Maybe<MutationUpdateProjectResult>;
};


export type MutationAcceptOrCreateFriendshipArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  recipientId: Scalars['String']['input'];
};


export type MutationAuthenticateArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  auth0token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCurrentUserArgs = {
  auth0token: Scalars['String']['input'];
  user: UserCreateInput;
};


export type MutationCreateProjectArgs = {
  project: ProjectCreateInput;
};


export type MutationDeclineFriendshipArgs = {
  requesterId: Scalars['String']['input'];
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationImpersonateArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  adminToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLikeImageArgs = {
  action: LikeTargetAction;
  id: Scalars['String']['input'];
  type: FileTypeLike;
};


export type MutationLikeVideoArgs = {
  action: LikeTargetAction;
  id: Scalars['String']['input'];
};


export type MutationMakeExceptionArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationNonShortlistApplicantArgs = {
  applicantId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  message: MessageCreateInput;
  recipientId: Scalars['String']['input'];
};


export type MutationSendNoFolioReminderEmailArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendNotificationArgs = {
  content: Scalars['String']['input'];
  recipientId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendWelcomeEmailFromAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationShortlistApplicantArgs = {
  applicantId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationShowInterestInProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateCurrentAccountArgs = {
  account: AccountUpdateInput;
};


export type MutationUpdateCurrentUserArgs = {
  user: UserUpdateInput;
};


export type MutationUpdateProjectArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  project: ProjectUpdateInput;
};

export type MutationAcceptOrCreateFriendshipResult = Account | Error;

export type MutationAuthenticateResult = CurrentUserWithToken | Error;

export type MutationCreateCurrentUserResult = CurrentUserWithToken | Error;

export type MutationCreateProjectResult = Error | ProjectWithAccount;

export type MutationDeclineFriendshipResult = Error | FriendshipWithAccount;

export type MutationDeleteAccountResult = Account | Error;

export type MutationImpersonateResult = CurrentUserWithToken | Error;

export type MutationLikeImageResult = Error | FolioItem;

export type MutationLikeVideoResult = Error | VideoItem;

export type MutationMakeExceptionResult = Account | Error;

export type MutationMarkAsReadResult = Account | Error;

export type MutationNonShortlistApplicantResult = Error | Project;

export type MutationSendMessageResult = Account | Error;

export type MutationSendNoFolioReminderEmailResult = Account | Error;

export type MutationSendNotificationResult = Account | Error;

export type MutationSendWelcomeEmailFromAdminResult = Account | Error;

export type MutationShortlistApplicantResult = Error | Project;

export type MutationShowInterestInProjectResult = Error | Project;

export type MutationUpdateCurrentAccountResult = Account | Error;

export type MutationUpdateCurrentUserResult = Error | User;

export type MutationUpdateProjectResult = Error | ProjectWithAccount;

export type Notification = {
  __typename?: 'Notification';
  addresseEmail?: Maybe<Scalars['String']['output']>;
  addressePhone?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  friendship?: Maybe<Friendship>;
  /** Could be a file url or an icon name */
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Message>;
  requesterEmail?: Maybe<Scalars['String']['output']>;
  requesterPhone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleUrl?: Maybe<Scalars['String']['output']>;
};

export type NotificationFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum NotificationReadState {
  All = 'ALL',
  Read = 'READ',
  Unread = 'UNREAD'
}

export type NotificationUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationUpdateInput = {
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderBy {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PantSizeType {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxxl = 'XXXL',
  Xxxxl = 'XXXXL',
  Xxxxxl = 'XXXXXL',
  Xxxxxxl = 'XXXXXXL'
}

export type PersonalDetails = {
  __typename?: 'PersonalDetails';
  accountId?: Maybe<Scalars['String']['output']>;
  agencyName?: Maybe<Scalars['String']['output']>;
  bust?: Maybe<Scalars['Int']['output']>;
  dressSize?: Maybe<DressSizeType>;
  eyes?: Maybe<EyesType>;
  hair?: Maybe<Hairtype>;
  height?: Maybe<Scalars['Int']['output']>;
  hips?: Maybe<Scalars['Int']['output']>;
  openToAgencies?: Maybe<Scalars['Boolean']['output']>;
  openToTestShoots?: Maybe<Scalars['Boolean']['output']>;
  pantSize?: Maybe<PantSizeType>;
  professionalLevel?: Maybe<ProfessionalLevel>;
  shoe?: Maybe<Scalars['Int']['output']>;
  waist?: Maybe<Scalars['Int']['output']>;
};

export type PersonalDetailsCreateInput = {
  agencyName?: InputMaybe<Scalars['String']['input']>;
  bust?: InputMaybe<Scalars['Int']['input']>;
  dressSize?: InputMaybe<DressSizeType>;
  eyes?: InputMaybe<EyesType>;
  hair?: InputMaybe<Hairtype>;
  height?: InputMaybe<Scalars['Int']['input']>;
  hips?: InputMaybe<Scalars['Int']['input']>;
  openToAgencies?: InputMaybe<Scalars['Boolean']['input']>;
  openToTestShoots?: InputMaybe<Scalars['Boolean']['input']>;
  pantSize?: InputMaybe<PantSizeType>;
  professionalLevel?: InputMaybe<ProfessionalLevel>;
  shoe?: InputMaybe<Scalars['Int']['input']>;
  waist?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonalDetailsUpdateInput = {
  agencyName?: InputMaybe<Scalars['String']['input']>;
  bust?: InputMaybe<Scalars['Int']['input']>;
  dressSize?: InputMaybe<DressSizeType>;
  eyes?: InputMaybe<EyesType>;
  hair?: InputMaybe<Hairtype>;
  height?: InputMaybe<Scalars['Int']['input']>;
  hips?: InputMaybe<Scalars['Int']['input']>;
  openToAgencies?: InputMaybe<Scalars['Boolean']['input']>;
  openToTestShoots?: InputMaybe<Scalars['Boolean']['input']>;
  pantSize?: InputMaybe<PantSizeType>;
  professionalLevel?: InputMaybe<ProfessionalLevel>;
  shoe?: InputMaybe<Scalars['Int']['input']>;
  waist?: InputMaybe<Scalars['Int']['input']>;
};

export type Profession = {
  __typename?: 'Profession';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProfessionFilter = {
  OR?: InputMaybe<Array<ProfessionFilter>>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionListFilter = {
  some?: InputMaybe<ProfessionFilter>;
};

export type ProfessionOrderBy = {
  name?: InputMaybe<OrderBy>;
};

export type ProfessionUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum ProfessionalLevel {
  Entry = 'Entry',
  Middle = 'Middle',
  Senior = 'Senior',
  Student = 'Student'
}

export type Profile = {
  __typename?: 'Profile';
  about?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<UserGender>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pronoun?: Maybe<UserPronoun>;
};

export type ProfileCreateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<UserGender>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pronoun?: InputMaybe<UserPronoun>;
};

export type ProfileFilter = {
  AND?: InputMaybe<Array<ProfileFilter>>;
  OR?: InputMaybe<Array<ProfileFilter>>;
  about?: InputMaybe<FullTextSearchFilter>;
  brand?: InputMaybe<FullTextSearchFilter>;
  location?: InputMaybe<FullTextSearchFilter>;
  name?: InputMaybe<FullTextSearchFilter>;
};

export type ProfileOrderBy = {
  brand?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

export type ProfileUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<UserGender>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pronoun?: InputMaybe<UserPronoun>;
};

export type Project = {
  __typename?: 'Project';
  account: Account;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  finishDate?: Maybe<Scalars['DateTime']['output']>;
  finishTime?: Maybe<Scalars['String']['output']>;
  folioItems?: Maybe<Array<FolioItem>>;
  hasCurrentAccountInterest: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interests?: Maybe<ProjectInterestsConnection>;
  location?: Maybe<Scalars['String']['output']>;
  professions?: Maybe<Array<Profession>>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  state: ProjectState;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProjectType>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProjectInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ProjectInterestsSearchQuery>;
};

export type ProjectCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  finishDate?: InputMaybe<Scalars['DateTime']['input']>;
  finishTime?: InputMaybe<Scalars['String']['input']>;
  folioItems?: InputMaybe<AccountUpdateFolioItemsRelationInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  professions?: InputMaybe<ProjectUpdateProfessionsRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ProjectState>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ProjectType>;
};

export type ProjectCreateProfessionsRelationInput = {
  connect?: InputMaybe<Array<ProfessionUniqueFilter>>;
};

export type ProjectFilter = {
  AND?: InputMaybe<Array<ProjectFilter>>;
  OR?: InputMaybe<Array<ProjectFilter>>;
  content?: InputMaybe<FullTextSearchFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<FullTextSearchFilter>;
  professions?: InputMaybe<ProfessionListFilter>;
  state?: InputMaybe<ProjectStateFilter>;
  title?: InputMaybe<FullTextSearchFilter>;
  type?: InputMaybe<ProjectTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProjectInterest = {
  __typename?: 'ProjectInterest';
  account: Account;
  id: Scalars['String']['output'];
  project: Project;
  state: ProjectInterestState;
};

export type ProjectInterestFilter = {
  AND?: InputMaybe<Array<ProjectInterestFilter>>;
  OR?: InputMaybe<Array<ProjectInterestFilter>>;
  account?: InputMaybe<AccountFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  state?: InputMaybe<ProjectInterestStateFilter>;
};

export type ProjectInterestOrderBy = {
  account?: InputMaybe<AccountOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
};

export enum ProjectInterestState {
  Nonshortlisted = 'NONSHORTLISTED',
  Shortlisted = 'SHORTLISTED'
}

export type ProjectInterestStateFilter = {
  equals?: InputMaybe<ProjectInterestState>;
  not?: InputMaybe<ProjectInterestStateFilter>;
};

export type ProjectInterestsConnection = {
  __typename?: 'ProjectInterestsConnection';
  edges: Array<ProjectInterestsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProjectInterestsConnectionEdge = {
  __typename?: 'ProjectInterestsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectInterest;
};

export type ProjectInterestsSearchQuery = {
  orderBy?: InputMaybe<ProjectInterestOrderBy>;
  where?: InputMaybe<ProjectInterestFilter>;
};

export type ProjectOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

export enum ProjectPreset {
  All = 'All',
  Mine = 'Mine',
  Others = 'Others'
}

export enum ProjectState {
  Active = 'Active',
  Done = 'Done',
  Draft = 'Draft',
  Empty = 'Empty',
  Published = 'Published'
}

export enum ProjectType {
  Art = 'Art',
  BrandShow = 'BrandShow',
  Campaign = 'Campaign',
  Casting = 'Casting',
  Commercial = 'Commercial',
  Contact = 'Contact',
  Content = 'Content',
  Contract = 'Contract',
  ECommerce = 'ECommerce',
  FashionShow = 'FashionShow',
  FitModel = 'FitModel',
  FullTime = 'FullTime',
  Other = 'Other',
  Paid = 'Paid',
  PartTime = 'PartTime',
  Test = 'Test',
  Unpaid = 'Unpaid'
}

export type ProjectUpdateFolioItemsRelationInput = {
  create?: InputMaybe<Array<FolioItemCreateInput>>;
  delete?: InputMaybe<Array<FolioUniqueFilter>>;
  update?: InputMaybe<Array<ProjectUpdateFolioItemsRelationInputUpdate>>;
};

export type ProjectUpdateFolioItemsRelationInputUpdate = {
  data?: InputMaybe<FolioItemUpdateInput>;
  where?: InputMaybe<FolioUniqueFilter>;
};

export type ProjectUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  finishDate?: InputMaybe<Scalars['DateTime']['input']>;
  finishTime?: InputMaybe<Scalars['String']['input']>;
  folioItems?: InputMaybe<ProjectUpdateFolioItemsRelationInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  professions?: InputMaybe<ProjectUpdateProfessionsRelationInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ProjectState>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ProjectType>;
};

export type ProjectUpdateProfessionsRelationInput = {
  connect?: InputMaybe<Array<ProfessionUniqueFilter>>;
  disconnect?: InputMaybe<Array<ProfessionUniqueFilter>>;
  set?: InputMaybe<Array<ProfessionUniqueFilter>>;
};

export type ProjectWithAccount = {
  __typename?: 'ProjectWithAccount';
  account: Account;
  project: Project;
};

export type ProjectsSearchQuery = {
  /** distance from current account in km */
  distance?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectOrderBy>;
  preset?: InputMaybe<ProjectPreset>;
  where?: InputMaybe<ProjectFilter>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<QueryAccountResult>;
  accounts: QueryAccountsConnection;
  accountsRandom: QueryAccountsRandomConnection;
  currentAccount?: Maybe<QueryCurrentAccountResult>;
  currentUser?: Maybe<QueryCurrentUserResult>;
  folioImages: QueryFolioImagesConnection;
  getFriendshipRequests?: Maybe<QueryGetFriendshipRequestsResult>;
  professions: Array<Profession>;
  project: QueryProjectResult;
  projects: QueryProjectsConnection;
  users: QueryUsersConnection;
};


export type QueryAccountArgs = {
  id: Scalars['String']['input'];
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<AccountsSearchQuery>;
};


export type QueryAccountsRandomArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<AccountsSearchQuery>;
};


export type QueryFolioImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ProjectsSearchQuery>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrderBy>;
  where?: InputMaybe<UserFilter>;
};

export type QueryAccountResult = Account | Error;

export type QueryAccountsConnection = {
  __typename?: 'QueryAccountsConnection';
  edges: Array<QueryAccountsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryAccountsConnectionEdge = {
  __typename?: 'QueryAccountsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type QueryAccountsRandomConnection = {
  __typename?: 'QueryAccountsRandomConnection';
  edges: Array<QueryAccountsRandomConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryAccountsRandomConnectionEdge = {
  __typename?: 'QueryAccountsRandomConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type QueryCurrentAccountResult = Account | Error;

export type QueryCurrentUserResult = Error | User;

export type QueryFolioImagesConnection = {
  __typename?: 'QueryFolioImagesConnection';
  edges: Array<QueryFolioImagesConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryFolioImagesConnectionEdge = {
  __typename?: 'QueryFolioImagesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FolioItem;
};

export type QueryGetFriendshipRequestsResult = Account | Error;

export type QueryProjectResult = Error | Project;

export type QueryProjectsConnection = {
  __typename?: 'QueryProjectsConnection';
  edges: Array<QueryProjectsConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryProjectsConnectionEdge = {
  __typename?: 'QueryProjectsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type QueryUsersConnection = {
  __typename?: 'QueryUsersConnection';
  edges: Array<QueryUsersConnectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryUsersConnectionEdge = {
  __typename?: 'QueryUsersConnectionEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type SocialInfo = {
  __typename?: 'SocialInfo';
  igHandle?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type SocialInfoCreateInput = {
  igHandle?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SocialInfoUpdateInput = {
  igHandle?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  accounts?: Maybe<Array<Account>>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UserRole>;
  settings?: Maybe<UserSettings>;
  state?: Maybe<UserState>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserCreateAccountsRelationInput = {
  create?: InputMaybe<Array<AccountCreateInput>>;
};

export type UserCreateInput = {
  accounts?: InputMaybe<UserCreateAccountsRelationInput>;
  settings?: InputMaybe<UserCreateSettingsRelationInput>;
};

export type UserCreateSettingsRelationInput = {
  create?: InputMaybe<UserSettingsCreateInput>;
};

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>;
  OR?: InputMaybe<Array<UserFilter>>;
};

export enum UserGender {
  Female = 'Female',
  Male = 'Male',
  Nonbinary = 'Nonbinary',
  Other = 'Other'
}

export type UserOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
};

export enum UserPronoun {
  He = 'He',
  She = 'She',
  Them = 'Them'
}

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  sendEmailNotification?: Maybe<Scalars['Boolean']['output']>;
};

export type UserSettingsCreateInput = {
  sendEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSettingsUpdateInput = {
  sendEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserState {
  Active = 'Active',
  Invited = 'Invited',
  Onboarding = 'Onboarding',
  Suspended = 'Suspended'
}

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<UserUpdateSettingsRelationInput>;
  state?: InputMaybe<UserUpdateableState>;
};

export type UserUpdateSettingsRelationInput = {
  update?: InputMaybe<UserSettingsUpdateInput>;
};

export enum UserUpdateableState {
  Active = 'Active',
  Onboarding = 'Onboarding'
}

export type VideoItem = {
  __typename?: 'VideoItem';
  caption?: Maybe<Scalars['String']['output']>;
  hasLiked: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  totalCountLikes: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type VideoItemCreateInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  kind: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type VideoItemUpdateInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type VideoUniqueFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FullTextSearchFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum LikeTargetAction {
  Dislike = 'Dislike',
  Like = 'Like'
}

export type ProjectStateFilter = {
  equals?: InputMaybe<ProjectState>;
  in?: InputMaybe<Array<ProjectState>>;
  notIn?: InputMaybe<Array<ProjectState>>;
};

export type ProjectTypeFilter = {
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<ProjectType>>;
  notIn?: InputMaybe<Array<ProjectType>>;
};
