'use client';

import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { renderTitleText } from '~/app/(me)/onboarding/_lib';
import { TrimmedCurrentAccountFragment } from '~/generated/graphql';
import { AppLayoutContext, IdentityContext } from '../../context';
import { Text, Title } from '@mantine/core';
function getFirstName(name: string | null | undefined) {
  return name?.split(' ')[0] ?? '';
}
function verifyTitleText(pathname: string, account?: TrimmedCurrentAccountFragment) {
  if (pathname !== '/onboarding/profile') {
    return renderTitleText(pathname, account);
  }
  if (!account) return 'Hi, tell us about yourself';
  return `Hi ${getFirstName(account.profile?.name)}, tell us about yourself`;
}
export function OnboardingTitle() {
  const pathname = usePathname();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <>
      <Text size={mobile ? '14px' : '19px'} fw={mobile ? 600 : 600} mx="xl" ta="center" lh={mobile ? '18px' : undefined} data-sentry-element="Text" data-sentry-source-file="OnboardingTitle.tsx">
        {verifyTitleText(pathname, currentAccount)}
      </Text>

      {pathname.includes('/onboarding/plan') ? <Text size={mobile ? '12px' : '10px'} fw={400} mt="10px">
          (Go pro with a 12 month free trial. No credit card required.)
        </Text> : null}
    </>;
}