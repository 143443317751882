import type { Route } from 'next'

export function onboardingRoute(): Record<string, Route> {
  return {
    createAccount: '/onboarding',
    profileBasicIndividual: '/onboarding/individual',
    profileBasicBusiness: '/onboarding/business',
    profileExtra: '/onboarding/profile',
    folio: '/onboarding/folio',
    choosePlan: '/onboarding/plan',
  }
}
