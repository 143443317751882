'use client';

import { DndContext, type DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Grid } from '@mantine/core';
import { useMutation, useQuery } from '@urql/next';
import { useSelector } from '@xstate/react';
import { Suspense, useContext } from 'react';
import { sortedFolioItems } from '~/app/(accounts)/_lib';
import { updateCurrentAccount } from '~/app/(me)/_lib';
import { inspectFolioItems } from '~/app/(me)/onboarding/_lib';
import { AppLayoutContext, AuthActorContext } from '~/app/_components';
import type { AccountUpdateInput } from '~/generated/graphql';
import { currentAccountFolio } from '~/graphql/resolvers/folio';
import { appTrace } from '~/utils';
import { arrayMoveImmutable } from '~/utils/arrayMove';
import { SortablePhoto } from '.';
export function GalleryGrid() {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const authState = useSelector(authActor, s => s);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [{
    data
  }] = useQuery({
    query: currentAccountFolio,
    pause: !(authState.matches('registration.onboarding.userCreated') || authState.matches('registration.complete'))
  });
  const [, executeUpdate] = useMutation(updateCurrentAccount);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates
  }));
  const folioItems = sortedFolioItems(data?.currentAccount);
  inspectFolioItems('rendered items', folioItems);
  function handleDragEnd(event: DragEndEvent) {
    const {
      active,
      over
    } = event;
    if (active.id !== over?.id) {
      const activeIndex = folioItems.findIndex(fi => fi.id === active?.id);
      const overIndex = folioItems.findIndex(fi => fi.id === over?.id);
      appTrace('moving: ', activeIndex, 'to', overIndex);
      inspectFolioItems('before: ', folioItems);
      const movedFolioItems = arrayMoveImmutable(folioItems, activeIndex, overIndex);
      inspectFolioItems('after: ', movedFolioItems);
      const account: AccountUpdateInput = {
        folioItems: {
          update: movedFolioItems.map((fi, order) => ({
            where: {
              id: fi.id
            },
            data: {
              order
            }
          }))
        }
      };
      executeUpdate({
        account
      }).then(response => {
        appTrace('executed update in gallery grid');
        const result = response.data?.updateCurrentAccount;
        if (result?.__typename === 'Error') {
          throw new Error(`Failed to update current account: ${result}`);
        }
      });
    }
  }
  if (!folioItems.length) return;
  return <Grid gutter="10px" m={0} data-sentry-element="Grid" data-sentry-component="GalleryGrid" data-sentry-source-file="GalleryGrid.tsx">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} data-sentry-element="DndContext" data-sentry-source-file="GalleryGrid.tsx">
        <SortableContext items={folioItems.map(fi => fi.id)} disabled={mobile} data-sentry-element="SortableContext" data-sentry-source-file="GalleryGrid.tsx">
          <Suspense data-sentry-element="Suspense" data-sentry-source-file="GalleryGrid.tsx">
            {folioItems.map(folio => <SortablePhoto key={folio.id} photo={folio.file.url} id={folio.id} />)}
          </Suspense>
        </SortableContext>
      </DndContext>
    </Grid>;
}