import { Flex, Input, Select, type MultiSelectProps, type SelectProps, type TextInputProps, type TextareaProps } from '@mantine/core';
import { useContext } from 'react';
import React from 'react';
import { type FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { TextInput, Textarea, MultiSelect } from 'react-hook-form-mantine';
import { textInputRoot } from '~/app/(me)/_components/common/styles.css';
import { AppLayoutContext, ValidationBadge } from '~/app/_components';
import { EyesType, Hairtype, PersonalDetails } from '~/generated/graphql';
import { ProfileFormValues } from './CurrentAccountFormProvider';
export type CommonFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label?: string | React.ReactNode;
};
export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function CommonFieldLabel<T extends FieldValues>(props: React.PropsWithChildren<T>) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const hideValidationBadgeFor = ['HEIGHT', 'ARE YOU OPEN TO BE SIGNED BY AN AGENCY?', 'PROFESSIONAL LEVEL', 'ARE YOU OPEN TO TEST SHOOTS?'];
  if (!props.label && !props.children) return null;
  return <Flex align="flex-start" mb={mobile ? '0px' : '3px'} data-sentry-element="Flex" data-sentry-component="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx">
      {props.label}
      {props.children}
      {!hideValidationBadgeFor.includes(props.label) && <ValidationBadge field={props.name} />}
    </Flex>;
}
export function CommonFieldLabelMeasurements<T extends FieldValues>(props: React.PropsWithChildren<T>) {
  if (!props.label && !props.children) return null;
  return <Flex align="center" miw="55px" justify="center" style={{
    fontSize: '14px',
    fontWeight: '400'
  }} data-sentry-element="Flex" data-sentry-component="CommonFieldLabelMeasurements" data-sentry-source-file="CommonFields.tsx">
      {capitalizeFirstLetter(props.label)}
      {props.children}
      <ValidationBadge field={props.name} data-sentry-element="ValidationBadge" data-sentry-source-file="CommonFields.tsx" />
    </Flex>;
}
export function CommonTextField<T extends FieldValues>(props: TextInputProps & CommonFieldProps<T>) {
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  const {
    mobile
  } = useContext(AppLayoutContext);
  // WARNING weird bug - if remove field.onChange it does not update
  const profileForm = useFormContext();
  const {
    field
  } = useController({
    name
  });
  return <Input.Label fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonTextField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <TextInput w="100%" placeholder={title} name={name} key={name} control={profileForm.control} size="md" autoCapitalize="true" onChange={field.onChange} classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="TextInput" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}
export function CommonTextareaField<T extends FieldValues>(props: TextareaProps & CommonFieldProps<T>) {
  const profileForm = useFormContext();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  return <Input.Label fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonTextareaField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <Textarea w="100%" key={name} placeholder={title} name={name} control={profileForm.control} size={mobile ? 'xl' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="Textarea" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}
export function CommonMeasurementSelectField<T extends PersonalDetails>(props: MultiSelectProps & CommonFieldProps<T>) {
  const profileForm = useFormContext<ProfileFormValues>();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  const fullFieldName: keyof ProfileFormValues = `personalDetails.${name}` as any;
  return <Input.Label fz={mobile ? 'h5' : 'h6'} fw={mobile ? 400 : undefined} data-sentry-element="unknown" data-sentry-component="CommonMeasurementSelectField" data-sentry-source-file="CommonFields.tsx">
      <Flex gap="3px" data-sentry-element="Flex" data-sentry-source-file="CommonFields.tsx">
        <CommonFieldLabelMeasurements {...props} data-sentry-element="CommonFieldLabelMeasurements" data-sentry-source-file="CommonFields.tsx" />
        {name === 'eyes' ? <>
            <CommonSelectField name={fullFieldName} size="sm" w={mobile ? '80px' : '80px'} placeholder="Select" data={[{
          value: EyesType.Brown,
          label: 'Brown'
        }, {
          value: EyesType.Blue,
          label: 'Blue'
        }]} clearable />
          </> : name === 'hair' ? <>
            <CommonSelectField name={fullFieldName} size="sm" w={mobile ? '80px' : '80px'} placeholder="Select" data={[{
          value: Hairtype.Black,
          label: 'Black'
        }, {
          value: Hairtype.Brown,
          label: 'Brown'
        }]} clearable />
          </> : name === 'shoe' ? <>
            <TextInput name={fullFieldName} w="80px" control={profileForm.control} classNames={{
          input: textInputRoot
        }} placeholder="US" />
          </> : <>
            <TextInput name={fullFieldName} w="80px" control={profileForm.control} classNames={{
          input: textInputRoot
        }} placeholder="cm" />
            {/* <CommonSelectField
                name={`${name}-metrics`}
                size="sm"
                w='68px'
                // placeholder="Select"
                data={[
                  { value: 'cm', label: 'cm' },
                  { value: 'inch', label: 'inch' },
                ]}
                clearable
              /> */}
          </>}
      </Flex>
    </Input.Label>;
}
export function CommonMultiselectField<T extends FieldValues>(props: MultiSelectProps & CommonFieldProps<T>) {
  const form = useFormContext<FieldValues>();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  // const currentValue = useWatch<FieldValues>({
  //   control: form.control,
  //   name,
  //   defaultValue: [],
  // })

  // const { setValue } = form
  // Subscribe to the field's value changes

  return <Input.Wrapper label={title}
  // description={props.description}
  fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonMultiselectField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />
      <MultiSelect w="100%" placeholder={title} name={name} control={form.control} size={mobile ? 'md' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="MultiSelect" data-sentry-source-file="CommonFields.tsx" />
    </Input.Wrapper>;
}

// WARNING this component is on purpose uncontrolled so that we can override and cast non-string default values
export function CommonSelectField<T extends FieldValues>(props: SelectProps & CommonFieldProps<T>) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    label,
    name,
    title,
    ...restProps
  } = props;
  const {
    field
  } = useController({
    name
  });
  const initialValue = field.value != null ? String(field.value) : '';
  return <Input.Label fz={mobile ? '13px' : 'h4'} fw={mobile ? 600 : 600} w="100%" data-sentry-element="unknown" data-sentry-component="CommonSelectField" data-sentry-source-file="CommonFields.tsx">
      <CommonFieldLabel {...props} data-sentry-element="CommonFieldLabel" data-sentry-source-file="CommonFields.tsx" />

      <Select w="100%" key={name} defaultValue={initialValue} onChange={(value: any) => field.onChange(String(value))} placeholder={props.placeholder || title} size={mobile ? 'xl' : 'md'} autoCapitalize="true" classNames={{
      input: textInputRoot
    }} {...restProps} data-sentry-element="Select" data-sentry-source-file="CommonFields.tsx" />
    </Input.Label>;
}